import React, { useState, useContext, useEffect } from 'react';
import { Page, Button, TextInput, Modal } from 'Stories';
import { useNavigate, useLocation } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { store } from 'Contexts/GlobalState';
import { alertClasses } from '@mui/material';

import { LIST_BATCHES, GET_KITS_IN_BATCH } from './GraphQL/queries.js';
import { CREATE_BATCH, PUT_BATCH_STATUS } from './GraphQL/mutations.js';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  LARGE_WINDOW_SIZE,
  MEDIUM_WINDOW_SIZE,
} from 'Services/GlobalConstants';
import { useWindowDimensions } from 'Services/CustomHooks';

import { BatchesInfo, BatchesTable, ScanKitsModal, CheckKit } from 'Components';

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
};

const STATUS_TRANSLATOR = {
  created: 'Created',
  created_ilab_request: 'Ready in iLab',
  ready_for_delivery: 'Ready for Delivery',
  delivered: 'Delivered',
  analyzing: 'Processing',
  completed: 'Results Ready',
  // cancelled: 'Cancelled',
  // finalizing: 'Making Request in iLab',
};

export default function Batches(props) {
  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;
  let navigate = useNavigate();

  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedBatchKits, setSelectedBatchKits] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [windowWidth, windowHeight] = useWindowDimensions();

  const [totalKits, setTotalKits] = useState(100);

  const [scanKitModalOpen, setScanKitModalOpen] = useState(false);
  const [totalRequested, setTotalRequested] = useState(0);

  const [page, setPage] = useState(0);

  const [numberOfBags, setNumberOfBags] = useState(0);
  const [numberOfSwabs, setNumberOfSwabs] = useState(0);

  useEffect(() => {
    getBatches();
  }, []);

  useEffect(() => {
    if (selectedBatch) {
      getBatchKits(selectedBatch.id);
    }
  }, [selectedBatch]);

  const [createBatch, { loading: createBatchLoading }] = useMutation(
    CREATE_BATCH,
    {}
  );

  const [
    listBatches,
    { loading: isBatchesLoading, data: taskData, error: taskError },
  ] = useLazyQuery(LIST_BATCHES, {
    fetchPolicy: 'no-cache',
  });

  const [getKitsInBatch, { loading: batchKitsLoading }] = useLazyQuery(
    GET_KITS_IN_BATCH,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [putBatchStatus, { loading: updateBatchStatusLoading }] = useMutation(
    PUT_BATCH_STATUS,
    { fetchPolicy: 'no-cache' }
  );

  const triggerCreateBatch = async (e) => {
    let resp = await createBatch();

    let data = JSON.parse(resp.data.createBatch.body);

    console.log(data);

    // setAllData([...allData, data]);
  };

  const toggleModal = (e) => {
    // setModalInfo(e);
    setModalOpen(!modalOpen);
  };

  const getBatches = async () => {
    let resp = await listBatches({
      variables: {
        info: {
          page: page,
          limit: 10,
        },
      },
    });
    console.log(resp);
    let data = JSON.parse(resp.data.listBatches.body);

    for (var i = 0; i < data.length; ++i) {
      data[i].active = data[i].active === false ? 'No' : 'Yes';
      data[i].displayStatus = STATUS_TRANSLATOR[data[i].status];
      data[i].actions = {
        edit: true,
        delete: false,
      };
    }
    console.log('SET DATA: ', data);
    setBatches(data);
    // console.log('RESP: ', resp.data);
  };

  const getBatchKits = async () => {
    let resp = await getKitsInBatch({
      variables: {
        batchId: selectedBatch.id,
      },
    });
    let batchKits = JSON.parse(resp.data.getKitsInBatch.body);
    // console.log('batchKits', batchKits);
    //! Test UI
    // batchKits = [
    //   ...batchKits,
    //   ...batchKits,
    //   ...batchKits,
    //   ...batchKits,
    //   ...batchKits,
    //   ...batchKits,
    //   ...batchKits,
    //   ...batchKits,
    //   ...batchKits,
    // ];
    setSelectedBatchKits(batchKits ? batchKits : []);
  };

  const updateBatchStatus = async (batchId, status) => {
    let resp = await putBatchStatus({
      variables: {
        batchId,
        status,
      },
    });
    let data = JSON.parse(resp.data.putBatchStatus.body);

    try {
      //* update ui with new status
      if (data.id) {
        const updatedBatch = {
          ...selectedBatch,
          status: data.status,
          displayStatus: STATUS_TRANSLATOR[data.status],
        };
        let updatedBatchList = batches;
        for (let [ind, batch] of updatedBatchList.entries()) {
          if (batch.id === selectedBatch.id) {
            updatedBatchList[ind] = updatedBatch;
          }
        }
        setBatches(updatedBatchList);
        setSelectedBatch(updatedBatch);
      }
    } catch (e) {
      //TODO
    }
  };

  const rowOnClick = (d) => {
    setSelectedBatch(d);
  };

  const onSubmitForm = async (e) => {
    await triggerCreateBatch();
    toggleModal();
    getBatches();
  };

  return (
    <>
      <Page pageTitle="Batches">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns:
              windowWidth < MEDIUM_WINDOW_SIZE ? '1fr' : '2fr 3fr',
          }}
        >
          <BatchesTable
            data={batches}
            loading={isBatchesLoading}
            rowOnClick={rowOnClick}
            handleCreateNew={toggleModal}
            noHeader
          />
          {windowWidth < MEDIUM_WINDOW_SIZE && (
            <div style={{ height: '25px' }}> </div>
          )}
          <BatchesInfo
            batch={selectedBatch}
            batchKits={selectedBatchKits}
            batchKitsLoading={batchKitsLoading}
            toggleScanKitModal={() => setScanKitModalOpen(!scanKitModalOpen)}
            updateBatchStatus={updateBatchStatus}
            updateBatchStatusLoading={updateBatchStatusLoading}
          />
        </div>
      </Page>
      <ScanKitsModal
        batchesPage
        open={scanKitModalOpen}
        prescannedKits={selectedBatchKits}
        batchId={selectedBatch ? selectedBatch.id : null}
        setSelectedKits={setSelectedBatchKits}
        kitsToScan={false}
        toggle={(e) => setScanKitModalOpen(!scanKitModalOpen)}
      />
      <Modal
        open={modalOpen}
        toggle={() => toggleModal()}
        title={'Create Batch?'}
      >
        <div style={{ padding: '30px 0px' }}>
          Are you sure you want to create a new batch?
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={buttonStyle}
            label="Cancel"
            onClick={toggleModal}
            disabled={createBatchLoading}
          />

          <Button
            style={{ ...buttonStyle, marginLeft: '20px' }}
            label={
              createBatchLoading ? (
                <div>
                  <CircularProgress
                    style={{ color: 'white', width: '15px', height: '15px' }}
                  />
                </div>
              ) : (
                'Create Batch'
              )
            }
            onClick={onSubmitForm}
            disabled={createBatchLoading}
          />
        </div>
      </Modal>
      <CheckKit />
    </>
  );
}
