import React, { useState, useContext, useEffect } from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from 'Stories';
import { CircularProgress } from '@mui/material';
import { pageSection } from 'Services/MUIStylesOverrides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

import './index.css';

const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 35,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // background: `linear-gradient(90deg, #66BEE7, #9D65F7)`,
  },
}));

export default function ScanKitsBar({
  current,
  numberOfSwabs,
  onClickButton,
  loading,
}) {
  const [kits, setKits] = useState([]);

  function LinearProgressWithLabel(props) {
    let amt = (props.current / props.total) * 100;

    return (
      <Box sx={{ alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <GradientLinearProgress
            variant="determinate"
            sx={{
              '& .MuiLinearProgress-bar': {
                background: `linear-gradient(90deg, #66BEE7 ${
                  100 - amt
                }%, #9D65F7 100%)`,
              },
            }}
            value={amt}
          />
        </Box>
        <div
          className="ScanKit_BarLabelContainer"
          style={{ color: amt > 55 ? 'white' : 'black' }}
        >
          {`${props.current}/${props.total}`}
        </div>
      </Box>
    );
  }

  return (
    <div className="ScanKit_SingleSectionHolder">
      <div className="ScanKit_SingleSectionTitle">
        <div>Kits Assigned</div>
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '50px',
            paddingRight: '50px',
            // boxShadow: '0px 0px 6px #aaaaaa',
          }}
          label="Scan Kits"
          disabled={current / numberOfSwabs >= 1}
          onClick={() => onClickButton(numberOfSwabs)}
        />
      </div>
      <div className="ScanKit_CompletedBar">
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              style={{
                height: '35px',
              }}
            />
          </div>
        ) : (
          <LinearProgressWithLabel total={numberOfSwabs} current={current} />
        )}
      </div>
    </div>
  );
}
