import React, { useRef } from 'react';

import { Button } from 'Stories';
import { useReactToPrint } from 'react-to-print';

import './index.css';

export default function DeliveryInformation({
  streetAddressOne,
  streetAddressTwo,
  city,
  state,
  zipCode,
  locationName,
}) {
  const shippingLabelRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => shippingLabelRef.current,
    // pageStyle: `@page { size: 2in 1.4in; margin: 0; }`, // Size of page template
    pageStyle: `@page { size: 3in 4in; margin: 1; }`, // Size of page template
  });

  return (
    <div className="Orders_SingleSectionHolder">
      <div className="Orders_SingleSectionTitle Orders_SingleSectionTitleSmall">
        <div>Deliver To</div>
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '50px',
            paddingRight: '50px',
            // boxShadow: '0px 0px 6px #aaaaaa',
          }}
          label="Print Label"
          // disabled={current / numberOfSwabs <= 1}
          onClick={handlePrint}
        />
      </div>
      <div className="Orders_InformationBasicCard" ref={shippingLabelRef}>
        <div className="Orders_InformationBasicTitle">{locationName}</div>
        <div>
          <div>{streetAddressOne}</div>
          <div>{streetAddressTwo}</div>
          <div>
            {city}, {state} {zipCode}
          </div>
        </div>
      </div>
    </div>
  );
}
