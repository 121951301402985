import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, Modal, Select } from 'Stories';
import { pageSection } from 'Services/MUIStylesOverrides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ScanKitsBar } from 'Components';
import DeliveryInformation from './DeliveryInformation';
import AccountInformation from './AccountInformation';

import './index.css';

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
};

const statusOptions = [
  { label: 'Created', value: 'created' },
  { label: 'Ready for Delivery', value: 'ready_for_delivery' },
  { label: 'Delivered', value: 'delivered' },
];

export default function OrdersInfo({
  order,
  toggleScanKitModal,
  isKitsForOrdersLoading,
  orderKits,
  updateOrderStatus,
  updateOrderStatusLoading,
}) {
  const [updateStatusModalOpen, setUpdateStatusModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(null);

  const toggleUpdateStatusModal = () => {
    setNewStatus(null);
    setUpdateStatusModalOpen(!updateStatusModalOpen);
  };

  const onSubmitForm = async () => {
    // console.log('SUBMITTING BUTTON', order.id, newStatus);
    const resp = await updateOrderStatus(order.id, newStatus);

    try {
      //TODO: update selectedOrder with updated status
      toggleUpdateStatusModal();
    } catch (e) {}
  };

  return (
    <Paper
      sx={{
        ...pageSection,
        height: '100%',
      }}
    >
      {order ? (
        <>
          <div className="OrderInfo_Container">
            <div
              className="header-container"
              style={{ padding: 0, paddingBottom: '20px' }}
            >
              <h2 className="clinic-info-header">{'Order #' + order.id}</h2>
            </div>
            <div>
              <div className="BatchesPanel_QuickInfoHolder">
                <div># of Swabs</div>
                <div>Requested By</div>
                <div>Clinic</div>
                <div>Status</div>
              </div>
              <div className="BatchesPanel_QuickInfoHolder OrderPanel_Data">
                <div>{order.numberOfSwabs}</div>
                <div>{order.orderedBy}</div>
                <div>{order.name}</div>
                <div>
                  {order.displayStatus}{' '}
                  <FontAwesomeIcon
                    icon={solid('pen-to-square')}
                    size="lg"
                    style={{ marginLeft: '20px', cursor: 'pointer' }}
                    onClick={toggleUpdateStatusModal}
                  />
                </div>
              </div>
            </div>
            <ScanKitsBar
              loading={isKitsForOrdersLoading}
              current={orderKits.length}
              numberOfSwabs={order.numberOfSwabs}
              onClickButton={toggleScanKitModal}
            />
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1, paddingRight: 10 }}>
                <DeliveryInformation
                  streetAddressOne={order.streetAddressOne}
                  streetAddressTwo={order.streetAddressTwo}
                  city={order.city}
                  state={order.state}
                  zipCode={order.zipCode}
                  locationName={order.locationName}
                  current={orderKits.length}
                  numberOfSwabs={order.numberOfSwabs}
                />
              </div>
              <div style={{ flex: 1, paddingLeft: 10 }}>
                <AccountInformation accountOwner={order.accountOwner} />
              </div>
            </div>
          </div>
          <Modal
            open={updateStatusModalOpen}
            toggle={toggleUpdateStatusModal}
            title="Update Order Status"
          >
            <div style={{ padding: '30px 0px' }}>
              This order's current status is <b>{order.displayStatus}</b>.
            </div>

            <Select
              options={statusOptions}
              value={order.status}
              onChange={(e) => {
                setNewStatus(e);
              }}
              style={{ marginBottom: '30px' }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={buttonStyle}
                label="Cancel"
                onClick={toggleUpdateStatusModal}
              />
              <Button
                style={{
                  ...buttonStyle,
                  marginLeft: '20px',
                  backgroundColor: !newStatus ? 'grey' : '#3dc1eb',
                  maxHeight: '40px',
                }}
                label={!updateOrderStatusLoading ? 'Update' : ''}
                loading={updateOrderStatusLoading}
                disabled={!newStatus}
                onClick={onSubmitForm}
              />
            </div>
          </Modal>
        </>
      ) : (
        <div className="select-clinic-message">Select Order</div>
      )}
    </Paper>
  );
}
