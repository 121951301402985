import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

let surveyBuilderIcons = {
  instructions: solid('circle-info'),
  openEnded: solid('align-left'),
  multipleChoice: solid('circle-dot'),
  selectMany: solid('square-check'),
  linearScale: solid('timeline'),
  datetime: solid('calendar-days'),
};

const colors = {
  primary: {
    navy: '#063C89',
    blue: '#3DC1EC',
    white: '#FFFFFF',
    black: '#000000',
  },
  secondary: {
    green: '#3A8C69',
    navy: '#031E45',
  },
  accent: {
    pink: '#DA345D',
    purple: '#8931FF',
    yellow: '#FFC630',
  },
};

let theme = createTheme({
  fontSize: {
    paragraph: '12px',
    tiny: '10px',
    default: '14px',
    header: '20px',
  },
  padding: {
    standard: '20px',
  },
  node: {
    marginBottom: '40px',
    boxShadow: '0px 0px 10px #cdcdcd',
    color: '#6E699D',
    backgroundColor: 'white',
    fontSize: '20px',
    padding: '10px 30px',
    width: 'fit-content',
    borderRadius: '7px',
  },
  revertNodeColors: {
    boxShadow: '0px 0px 10px #a7a7a7',
    backgroundColor: '#6E699D',
    color: 'white',
  },
  icons: {
    surveyBuilderIcons: surveyBuilderIcons,
  },
  palette: {
    general: {
      boxShadow: '0px 0px 5px #d3d3d3',
      ...colors,
      background: {
        main: '#f4f4f4',
      },
    },
    slides: {
      defaultOrder: ['blue', 'teal', 'white', 'green', 'navy'],
      blue: {
        background: '#063C89',
        text: '#FFFFFF',
        accent: '#3DC1EC',
      },
      teal: {
        background: '#3DC1EC',
        text: '#000000',
        accent: '#FFFFFF',
      },
      white: {
        background: '#FFFFFF',
        text: '#000000',
        accent: '#3DC1EC',
      },
      green: {
        background: '#3A8C69',
        text: '#FFFFFF',
        accent: '#000000',
      },
      navy: {
        background: '#031E45',
        text: '#FFFFFF',
        accent: '#3DC1EC',
      },
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
