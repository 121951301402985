import { CircularProgress } from '@mui/material';

import './index.css';

export default function OrderHighlightNumber({
  number,
  text,
  errorMessage,
  isError,
  onClick,
  loading,
}) {
  const alterNumber = (num, digits) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast((item) => num >= item.value);
    return item
      ? (num / item.value)
          .toFixed(digits)
          .replace(regexp, '')
          .concat(item.symbol)
      : '0';
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className="HighlightNumber_Container"
      style={{
        color: isError ? 'white' : '',
        backgroundColor: isError ? '#FF4B70' : '',
        cursor: onClick ? 'pointer' : '',
      }}
      onClick={handleClick}
    >
      {loading ? (
        <div style={{ margin: '0 auto' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="HighlightNumber_Number">{alterNumber(number, 2)}</div>
          <div className="HighlightNumber_Text">{text}</div>
          <div className="HighlightNumber_ErrorText">{errorMessage}</div>
        </>
      )}
    </div>
  );
}
