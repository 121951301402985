import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { CHECK_KIT } from '../GraphQL/queries.js';

import './index.css';

let dataToShow = [
  {
    key: 'id',
    label: 'Kit ID',
  },
  {
    key: 'batchId',
    label: 'Batch ID',
  },
  {
    key: 'orderId',
    label: 'Order ID',
  },
  {
    key: 'patientId',
    label: 'Patient ID',
  },
  {
    key: 'clinic',
    label: 'Clinic',
  },
];

export default function ScanKitsModal() {
  const inputRef = useRef();

  const [readyToScan, setReadyToScan] = useState(false);
  const [kitData, setKitData] = useState({});

  const [open, setOpen] = useState(false);

  const [checkKit, { loading: checkKitLoading }] = useLazyQuery(CHECK_KIT, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!open) {
      setKitData({});
    } else {
      setTimeout(() => {
        focusOnField();
      }, 2000);
    }
  }, [open]);

  const runKitId = async (id) => {
    let resp = await checkKit({
      variables: {
        kitId: id,
      },
    });

    let data = JSON.parse(resp.data.checkKit.body);
    setKitData(data);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      let id = inputRef.current.value;

      if (id) {
        runKitId(id);
      }
    }
  };

  const onFocus = () => setReadyToScan(true);
  const onBlur = () => setReadyToScan(false);

  const focusOnField = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className="CheckKit_Button" onClick={toggle}>
        <FontAwesomeIcon icon={solid('barcode')} />
      </div>
      <Modal open={open} onClose={toggle}>
        {/* Add close modal icon */}
        <Box
          sx={style}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <h2 className="header">Scan Kit to Check Info</h2>
          </div>
          <div className="CheckKit_DataHolder">
            {dataToShow.map((item) => {
              return (
                <div className="CheckKit_Data">
                  <div style={{ fontWeight: 'bold' }}>{item.label}</div>
                  <div>
                    {kitData && kitData[item.key] ? kitData[item.key] : '---'}
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ opacity: '0' }}>
            <input
              aria-autocomplete="none"
              autocomplete="off"
              // autocomplete="false"
              type="text"
              name="kit"
              id="kit"
              ref={inputRef}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={handleKeyDown}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const style = {
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '50%',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};
