import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

export default function ButtonX(props) {
  const [value, setValue] = useState(null);
  const theme = useTheme();

  const handleOnChange = (e) => {
    if (props.onPress) {
      props.onPress();
    }
    if (props.onClick) {
      props.onClick();
      if (props.ignoreDefault) {
        e.preventDefault();
      }
    }
  };

  const getBgColor = () => {
    if (props.theme === 'dark') {
      return 'var(--primaryDark)';
    } else if (props.theme === 'error') {
      return '#DA345D';
    } else {
      return 'var(--primaryLight)';
    }
  };

  return (
    <Button
      id={props.id}
      variant={props.variant}
      type={props.type}
      onClick={(e) => handleOnChange(e)}
      style={{
        backgroundColor: getBgColor(),
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        padding: '12px',
        fontSize: 'clamp(12px, 2vw, 15px)',
        borderRadius: 30,
        minWidth: '255px',
        width: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.style,
      }}
      disabled={props.disabled}
      sx={props.sx}
    >
      {props.icon && <div style={{ paddingRight: '5px' }}>{props.icon}</div>}
      {props.label}
    </Button>
  );
}

ButtonX.propTypes = {
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  onClick: PropTypes.func,
};

ButtonX.defaultProps = {
  id: 'NO-ID-PROVIDED',
  label: 'NO-LABEL-PROVIDED',
  variant: 'contained',
  type: undefined,
};
