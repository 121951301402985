import React, { useState, useEffect } from 'react';

import { Button } from 'Stories';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { dialog } from 'Services/MUIStylesOverrides';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={open} minWidth={'md'} maxWidth={'md'} sx={dialog}>
      <DialogTitle sx={{ padding: 0 }} style={{ alignContent: 'center' }}>
        <div className="Home_CardTitle" style={{ fontSize: '1.5em' }}>
          {props.title}
          <IconButton
            style={{ float: 'right', color: 'white' }}
            onClick={props.toggle}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {props.children}
        {props.confirmation && (
          <div style={{ display: 'flex', paddingTop: '25px' }}>
            <Button
              label="cancel"
              id="cancel"
              type="cancel"
              onClick={props.toggleModal}
            />
            <div style={{ width: '10px' }}> </div>
            <Button
              label="Confirm"
              id="submit"
              type="submit"
              onClick={props.onClickDelete}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
