import React from 'react';

import './Menu.css';

export default function MenuHeader({ icon, title }) {
  return (
    <div className={'mycare-icon-container'}>
      <div className={'mycare-icon'}>{icon}</div>
      <div className={'mycare-text'}>{title}</div>
    </div>
  );
}
