import { gql } from '@apollo/client';

export const LIST_ORDERS = gql`
  query ($info: PaginationInput, $type: String) {
    listOrders(info: $info, type: $type) {
      status
      body
    }
  }
`;

export const GET_ORDER = gql`
  query ($orderId: String) {
    getOrder(orderId: $orderId) {
      status
      body
    }
  }
`;

export const GET_KITS_FOR_ORDER = gql`
  query ($orderId: String) {
    getKitsForOrder(orderId: $orderId) {
      status
      body
    }
  }
`;

export const LIST_CLINICS = gql`
  query ($info: PaginationInput) {
    listClinics(info: $info) {
      status
      body
    }
  }
`;

export const LIST_LOCATIONS_FOR_CLINIC = gql`
  query ($clinicId: String) {
    listLocationsForClinic(clinicId: $clinicId) {
      status
      body
    }
  }
`;

export const GET_KITS_IN_INVENTORY = gql`
  query {
    getKitsInInventory {
      status
      body
    }
  }
`;

export const GET_KITS_CHECKED_OUT = gql`
  query {
    getKitsCheckedOut {
      status
      body
    }
  }
`;
