import React, { useState, useEffect } from 'react';
import { Form, Button, Select, Table } from 'Stories';
import CreateKitFormContent from './components/modal/CreateKitFormContent';
import ViewKitFormContent from './components/modal/ViewKitFormContent';
import BulkUpdateKitsFormContent from './components/modal/BulkUpdateKitsFormContent';

// const kitStatusOptions = [
//   { value: 'received', label: 'Received' },
//   { value: 'analyzing', label: 'Analyzing' },
//   { value: 'completed', label: 'Completed' },
// ];

export default function PageForm(props) {
  const [barcodeImageFile, setBarcodeImageFile] = useState(null);

  const renderFormContent = () => {
    if (props.viewKitModal) {
      return <ViewKitFormContent kitModalInfo={props.kitModalInfo} />;
    } else if (props.createKitModal) {
      return (
        <CreateKitFormContent
          barcodeImageFile={barcodeImageFile}
          setBarcodeImageFile={setBarcodeImageFile}
          setModalOpen={props.setModalOpen}
        />
      );
    } else if (props.bulkUpdateModal){
      return <BulkUpdateKitsFormContent bulkSelectedKits={props.bulkSelectedKits}/>
    }
  };

  //TODO: maintain modal container size

  return (
    <div>
      {/* {props.viewKitModal ? (
        <ViewKitFormContent kitModalInfo={props.kitModalInfo} />
      ) : props.createKitModal ? (
        <>
          <CreateKitFormContent
            barcodeImageFile={barcodeImageFile}
            setBarcodeImageFile={setBarcodeImageFile}
            setModalOpen={props.setModalOpen}
          />
        </>
      ) : null} */}
      {renderFormContent()}
    </div>
  );
}
