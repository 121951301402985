import React, { useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import Lottie from 'lottie-react';
import loadingGif from 'Assets/dna_loader.json';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--secondaryDark)',
    color: theme.palette.common.white,
    marginBottom: '25px',
    boxShadow: 'none',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.MuiTableRow-root': {
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s linear',
  },
  '&:hover': {
    backgroundColor: '#bdbdbdee !important',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#e7e7e7',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function BasicTable(props) {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  const [selectedData, setSelectedData] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);

  const [distanceBottom, setDistanceBottom] = useState(0);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(props.sortDefault);

  const tableEl = useRef();

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property, isNumeric) => {
    const isAsc = orderBy === property && order === 'asc';
    let newOrder = isAsc ? 'desc' : 'asc';

    if (property !== orderBy && isNumeric) {
      newOrder = 'desc';
    }

    setOrder(newOrder);
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () => stableSort(props.data, getComparator(order, orderBy)),
    [order, orderBy, props.data]
  );

  const createSortHandler = (property, isNumeric) => (event) => {
    handleRequestSort(event, property, isNumeric);
  };

  useEffect(() => {
    setCheckedItems([]);
  }, [props.tabSelected, props.clearSelectionData]);

  useEffect(() => {
    setSelectedData(props.selectedData);
  }, [props.selectedData]);

  useEffect(() => {
    if (props.selectHandler) {
      props.selectHandler(checkedItems);
    }
  }, [checkedItems]);

  const handleCheckClicked = (e, checked) => {
    if (checked) {
      setCheckedItems([...checkedItems, e]);
    } else {
      let dupeArr = [...checkedItems];
      let foundInd = dupeArr.indexOf(e);
      if (foundInd > -1) {
        dupeArr.splice(foundInd, 1);
      }

      setCheckedItems(dupeArr);
    }
  };

  const onSelectAllClick = (e) => {
    if (checkedItems.length === props.data.length) {
      setCheckedItems([]);
    } else {
      let dupeArr = [...checkedItems];
      for (var i = 0; i < props.data.length; ++i) {
        if (dupeArr.indexOf(props.data[i].patientId) === -1) {
          dupeArr.push(props.data[i].patientId);
        }
      }
      setCheckedItems(dupeArr);
    }
  };

  // const scrollListener = useCallback(() => {
  //   let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight

  //   if (!distanceBottom) {
  //     // calculate distanceBottom that works for you
  //     setDistanceBottom(Math.round(bottom * 0.2))
  //   }
  //   if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
  //     loadMore()
  //   }
  // }, [hasMore, loadMore, loading, distanceBottom])

  // useLayoutEffect(() => {
  //   const tableRef = tableEl.current;
  //   tableRef.addEventListener('scroll', scrollListener);
  //   return () => {
  //     tableRef.removeEventListener('scroll', scrollListener);
  //   };
  // }, [scrollListener]);

  const hasMore = () => {
    return props.totalRowCount > props.data.length && !props.searchActive;
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: 'none', maxHeight: '50vh' }}
      ref={tableEl}
    >
      <Table
        aria-label="customized table"
        stickyHeader
        sx={{
          tableLayout:
            props.headers && props.headers[0].widthPercentage ? 'fixed' : '',
        }}
        size={'small'}
      >
        <TableHead>
          <TableRow>
            {props.enableMultiSelect && (
              <StyledTableCell padding="checkbox">
                {' '}
                <Checkbox
                  color="primary"
                  indeterminate={
                    checkedItems.length > 0 &&
                    checkedItems.length < props.data.length
                  }
                  checked={
                    props.data.length > 0 &&
                    checkedItems.length === props.data.length
                  }
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                  }}
                  onChange={onSelectAllClick}
                />
              </StyledTableCell>
            )}
            {props.headers.map((item) => {
              return (
                <StyledTableCell
                  size={item.size ? item.size : 'medium'}
                  align={item.align ? item.align : 'left'}
                  sx={{
                    width: item.widthPercentage
                      ? item.widthPercentage
                      : 'unset',
                    padding: item.widthPercentage ? '0px' : '',
                  }}
                >
                  {props.sortable && !item.ignoreRowClick ? (
                    <TableSortLabel
                      active={orderBy === item.key}
                      direction={orderBy === item.key ? order : 'asc'}
                      onClick={createSortHandler(item.key, item.isNumeric)}
                      style={{ color: 'white !important' }}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: 'white !important',
                        },
                        color: 'white !important',
                      }}
                    >
                      {item.name}
                      {orderBy === item.key ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <div>{item.name}</div>
                  )}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <div style={{ height: '20px' }}> </div>
        <TableBody>
          {props.loading && (
            <StyledTableRow
              sx={{
                height: '200px',
                backgroundColor: 'white !important',
                '&:hover': {
                  backgroundColor: 'white !important',
                },
              }}
            >
              <StyledTableCell
                align="center"
                colSpan={
                  props.headers.length + (props.enableMultiSelect ? 1 : 0)
                }
              >
                <Lottie
                  style={{
                    width: '60%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    transform: 'rotate(20deg)',
                    maxWidth: 200,
                  }}
                  animationData={loadingGif}
                  loop={true}
                />
              </StyledTableCell>
            </StyledTableRow>
          )}
          {!props.loading && props.data.length === 0 && (
            <StyledTableRow
              sx={{
                height: '200px',
                backgroundColor: 'white !important',
                '&:hover': {
                  backgroundColor: 'white !important',
                },
              }}
            >
              <StyledTableCell
                align="center"
                colSpan={
                  props.headers.length + (props.enableMultiSelect ? 1 : 0)
                }
              >
                No data
              </StyledTableCell>
            </StyledTableRow>
          )}
          {!props.loading &&
            visibleRows.map((dataItem, index) => {
              return (
                <StyledTableRow
                  hover
                  sx={{
                    cursor: 'pointer',
                    backgroundColor:
                      selectedData &&
                      selectedData[props.selectedKey] ===
                        dataItem[props.selectedKey]
                        ? '#afdeff !important'
                        : 'white',
                    '&:hover': {
                      backgroundColor:
                        props.ignoreNonActiveRows && !dataItem.active
                          ? index % 2 === 0
                            ? '#e7e7e7 !important'
                            : 'white !important'
                          : '#bdbdbd !important',
                    },
                  }}
                  onClick={(event) => {
                    if (
                      !props.ignoreNonActiveRows ||
                      (props.ignoreNonActiveRows && dataItem.active)
                    )
                      props.onRowClick(dataItem);
                  }}
                >
                  {props.enableMultiSelect && (
                    <StyledTableCell padding="checkbox">
                      {' '}
                      <Checkbox
                        color="primary"
                        checked={checkedItems.indexOf(dataItem.patientId) > -1}
                        onClick={(e) => {
                          handleCheckClicked(
                            dataItem.patientId,
                            e.target.checked
                          );
                          e.stopPropagation();
                        }}
                        sx={{
                          '&.Mui-checked': {
                            color: 'var(--secondaryDark)',
                          },
                        }}
                      />
                    </StyledTableCell>
                  )}
                  {props.headers.map((headerKey) => {
                    return (
                      <StyledTableCell
                        size={headerKey.size ? headerKey.size : 'medium'}
                        align={headerKey.align ? headerKey.align : 'left'}
                        sx={{
                          color:
                            props.ignoreNonActiveRows && !dataItem.active
                              ? '#bfbfbf !important'
                              : '',
                        }}
                        onClick={(e) => {
                          if (headerKey.ignoreRowClick) e.stopPropagation();
                        }}
                      >
                        {dataItem[headerKey.key]
                          ? dataItem[headerKey.key]
                          : '---'}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          {props.infiniteScroll && (
            <StyledTableRow
              onClick={() => {
                if (hasMore()) {
                  props.loadMoreHandler();
                }
              }}
              sx={{
                backgroundColor: 'white !important',
                '&:hover': {
                  backgroundColor: 'white !important',
                  color: hasMore() ? 'var(--primaryLight)' : '#bdbdbd',
                },
              }}
            >
              <StyledTableCell
                align="center"
                colSpan={props.headers.length}
                style={{
                  fontWeight: 'bold',
                  cursor: hasMore() ? 'pointer' : 'default',
                  color: hasMore() ? 'var(--secondaryDark)' : '#bdbdbd',
                }}
              >
                {hasMore() ? 'View More' : 'No more rows'}
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
