import React, { useState, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';
import { Auth } from 'aws-amplify';
import QRCode from 'qrcode';

export default function CustomSetupTOTP(props) {

  console.log(props);

  const [isLoading, setIsLoading] = useState(true);
  const [isVerifyingToken, setIsVerifyingToken] = useState(false);
  const [qrCode, setQrCode] = React.useState('');
  const [token, setToken] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const getTotpCode = (issuer, username, secret) =>
    encodeURI(
      `otpauth://totp/${issuer}:${username}?secret=${secret}&issuer=${issuer}`
    );

  const totpUsername = props.user?.getUsername() || '';

  const generateQRCode = React.useCallback(
    async (currentUser) => {
      try {
        const newSecretKey = await Auth.setupTOTP(currentUser);
        const totpCode = getTotpCode('HEALAZInventory' + (window.location.host === 'inventory.healaz.com' ? 'PROD' : 'DEV'), totpUsername, newSecretKey);
        const qrCodeImageSource = await QRCode.toDataURL(totpCode);
        setQrCode(qrCodeImageSource);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [props.issuer, totpUsername]
  );

  const verifyTotpToken = () => {
    setErrorMessage('');
    setIsVerifyingToken(true);

    if (props.type === 'setupMFA') {
      Auth.verifyTotpToken(props.user, token)
        .then(async () => {
          await Auth.setPreferredMFA(props.user, 'TOTP');
          return null;
        })
        .catch((e) => {
          console.error('failed on verify', e);
          if (/Code mismatch/.test(e.toString())) {
            setErrorMessage('Code Mismatch');
          }
        })
        .finally(() => setIsVerifyingToken(false));
    } else {
      Auth.confirmSignIn(props.user, token, 'SOFTWARE_TOKEN_MFA')
        .then(async () => {
          return null;
        })
        .catch((e) => {
          console.error('failed on verify', e);
          if (/Code mismatch/.test(e.toString())) {
            setErrorMessage('Failed to Refresh Session');
          }
        })
        .finally(() => setIsVerifyingToken(false));
    }
  };

  useEffect(() => {
    if (!props.user) {
      return;
    }
    if (props.type === 'setupMFA') {
      void generateQRCode(props.user);
    }
  }, [generateQRCode, props.user, props.type]);

  const isValidToken = () => {
    return /^\d{6}$/gm.test(token);
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="pageTitle">
        {props.type === 'setupMFA' ? 'Setup MFA Token' : 'Enter MFA Token'}
      </div>
      {(!isLoading || props.type === 'enterMFA') && (
        <div>
          {props.type === 'setupMFA' && (
            <div style={{ textAlign: 'center' }}>
              <img
                data-amplify-qrcode
                src={qrCode}
                name="MP Inventory"
                alt="qr code"
                width="228"
                height="228"
              />
            </div>
          )}
          <Form style={{ display: 'block' }} onSubmit={verifyTotpToken}>
            <TextInput
              variant="outlined"
              onChange={(e) => {
                setToken(e);
              }}
            ></TextInput>

            <Button
              size="large"
              disabled={!isValidToken() || isVerifyingToken}
              color="primary"
              variant="contained"
              type="submit"
              //   onClick={verifyTotpToken}
              style={{ marginTop: '10px', width: '100%' }}
              label={
                isVerifyingToken ? 'Verifying MFA Token' : 'Enter MFA Token'
              }
            />
            {errorMessage && (
              <div className="authErrorMessage">{errorMessage}</div>
            )}
          </Form>
        </div>
      )}
    </div>
  );
}
