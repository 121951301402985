import React, { useEffect, useState, useRef } from 'react';
import Quagga from '@ericblade/quagga2';
import { TextInput } from 'Stories';
import { DisplayBarcode } from '../Barcode/DisplayBarcode';
import { CircularProgress, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useReactToPrint } from 'react-to-print';

import { useMutation } from '@apollo/client';
import { CREATE_KIT } from 'Pages/Inventory/GraphQL/mutations';

export default function CreateKitFormContent({
  barcodeImageFile,
  setBarcodeImageFile,
  setModalOpen,
}) {
  const [createKit, { loading: createKitLoading }] = useMutation(CREATE_KIT);
  const [decodedKitID, setDecodedKitID] = useState(null);
  const [decodeError, setDecodeError] = useState(null);
  const [screen, setScreen] = useState('form'); //* enum: form, success, & error

  const theme = useTheme();
  const barcodeRef = useRef();

  const buttonStyle = {
    backgroundColor: theme.palette.general.blue,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  };

  useEffect(() => {
    if (barcodeImageFile) {
      decodeBarcodeImageID(barcodeImageFile);
    }
  }, [barcodeImageFile]);

  const onSubmit = async () => {
    let payload = { id: decodedKitID };

    let resp = await createKit({ variables: { info: payload } });
    try {
      let { body } = resp.data.createKit;
      console.log(resp.data.createKit);

      //TODO: Improve Error Handling - error with adding kit will return 'null' but a status code of 200 (i.e. attempting to add an existing kit ID)
      if (body === 'null') {
        //* Show Error Screen
        setScreen('error');
      } else {
        //* Show Success Screen
        setScreen('success');
      }
    } catch (err) {
      console.log('Error with createKit: ', err, resp);
    }
  };

  const decodeBarcodeImageID = (img) => {
    Quagga.decodeSingle(
      {
        locate: true,
        src: URL.createObjectURL(barcodeImageFile),
      },
      (res) => {
        console.log(res);
        console.log(URL.createObjectURL(barcodeImageFile));
        if (res && res.codeResult) {
          console.log('Barcode ID Detected: ', res.codeResult.code);

          if (decodeError) setDecodeError(null);

          setDecodedKitID(res.codeResult.code);
        } else {
          //! Error
          console.log('Barcode ID Not Detected. Try another image');

          setDecodeError('Barcode ID Not Detected. Try another image');

          setDecodedKitID(null);
        }
      }
    );
  };

  const handlePrint = useReactToPrint({
    content: () => barcodeRef.current,
    pageStyle: `@page { size: 2in 2.5in; margin: 0; }`, // Size of page template
  });

  return (
    <>
      {/* Add Kit Form */}
      {screen === 'form' && (
        <>
          <Button component="label" style={buttonStyle}>
            Upload Barcode Image
            <input
              // value={barcodeImage}
              onChange={(e) => {
                console.log(e.target.files);
                setBarcodeImageFile(e.target.files[0]);
              }}
              label="Upload Image"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
            />
          </Button>
          {decodeError && <p style={{ color: 'red' }}>{decodeError}</p>}
          {barcodeImageFile && (
            <div>
              <img
                id="barcodePreview"
                src={URL.createObjectURL(barcodeImageFile)}
                alt="Barcode Preview"
                style={{ maxHeight: '300px' }}
              />
            </div>
          )}
          <TextInput
            label="Kit ID"
            value={decodedKitID}
            onChange={(e) => setDecodedKitID(e)}
          />
          {!createKitLoading ? (
            <Button style={buttonStyle} label="submit" onClick={onSubmit}>
              Submit
            </Button>
          ) : (
            <CircularProgress />
          )}
        </>
      )}
      {screen === 'success' && (
        <>
          <h2>Kit ID #{decodedKitID} Sucessfully Created!</h2>
          <>
            <DisplayBarcode kitId={decodedKitID} ref={barcodeRef} />
            <Button style={buttonStyle} label="Close" onClick={handlePrint}>
              Print Barcode
            </Button>
            <Button
              style={buttonStyle}
              label="Close"
              onClick={() => setModalOpen(false)}
            >
              Close
            </Button>
          </>
        </>
      )}
      {screen === 'error' && (
        <>
          <h2>Error</h2>
          <p>
            There was an issue adding this kit. Please check if there is an
            existing kit with the same ID
          </p>
          <>
            <Button
              style={buttonStyle}
              label="Back"
              onClick={() => setScreen('form')}
            >
              Back
            </Button>
            <Button
              style={buttonStyle}
              label="Close"
              onClick={() => setModalOpen(false)}
            >
              Close
            </Button>
          </>
        </>
      )}
    </>
  );
}
