import React from 'react';
import Barcode from 'react-barcode';

export const DisplayBarcode = React.forwardRef((props, ref) => {
  // console.log('barcodeID: ', props.kitId);
  return (
    <Barcode
      ref={ref}
      value={props.kitId}
      format="CODE128"
      displayValue={true}
    />
  );
});
