import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Modal, Select } from 'Stories';
import { CircularProgress } from '@mui/material';

import { PUT_ORDER } from './GraphQL/mutations.js';
import {
  GET_ORDER,
  LIST_CLINICS,
  LIST_LOCATIONS_FOR_CLINIC,
} from './GraphQL/queries.js';
import { useMutation, useLazyQuery } from '@apollo/client';

import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 12);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '50%',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export default function CreateOrderModal({ open, toggle }) {
  const [clinic, setClinic] = useState(null);
  const [location, setLocation] = useState(null);

  const [clinicList, setClinicList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [locationDict, setLocationDict] = useState({});

  const [listClinics, { loading: listClinicsLoading }] = useLazyQuery(
    LIST_CLINICS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [loadLocationsForClinic, { loading: loadingLocationsForClinic }] =
    useLazyQuery(LIST_LOCATIONS_FOR_CLINIC, {
      fetchPolicy: 'no-cache',
    });

  const [putOrder, { loading: putOrderLoading }] = useMutation(PUT_ORDER, {
    fetchPolicy: 'no-cache',
  });

  const onSubmit = async (e) => {
    let payload = {
      orderId: nanoid(),
      clinicId: e.Clinic,
      location: location,
      numberOfSwabs: e.numberOfSwabs,
    };

    let resp = await putOrder({ variables: { info: payload } });
    toggle('created');
  };

  const loadLocations = async (e) => {
    setClinic(e);

    let resp = await loadLocationsForClinic({ variables: { clinicId: e } });

    let locationsRaw = JSON.parse(resp.data.listLocationsForClinic.body);

    let dict = {};
    let arr = [];

    for (var i = 0; i < locationsRaw.length; ++i) {
      dict[locationsRaw[i].id] = locationsRaw[i];
      arr.push({
        value: locationsRaw[i].id,
        label: (
          <div>
            {locationsRaw[i].name}
            <div style={{ fontSize: '10px' }}>
              {locationsRaw[i].streetAddressOne}
            </div>
            <div style={{ fontSize: '10px' }}>
              {locationsRaw[i].streetAddressTwo}
            </div>
            <div style={{ fontSize: '10px' }}>
              {locationsRaw[i].city}, {locationsRaw[i].state}
            </div>
          </div>
        ),
      });
    }

    setLocationDict(dict);
    setLocationList(arr);
  };

  const getClinics = async () => {
    setLocation(null);
    setLocationList([]);
    setLocationDict({});
    let resp = await listClinics({
      info: {
        page: 0,
        limit: 50,
      },
    });

    let rawData = JSON.parse(resp.data.listClinics.body);

    let data = [];

    for (var i = 0; i < rawData.length; ++i) {
      data.push({
        value: rawData[i].id,
        label: rawData[i].name,
      });
    }

    setClinicList(data);
  };

  const initData = () => {
    getClinics();
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Modal open={open} toggle={toggle} title="Create Order">
      {/* Add close modal icon */}
      {listClinicsLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            flexDirection: 'column',
          }}
        >
          <div style={{ padding: '25px' }}>Loading Clinics</div>
          <div style={{ padding: '25px' }}>
            {' '}
            <CircularProgress />
          </div>
        </div>
      ) : (
        <Form style={{ display: 'block' }} onSubmit={onSubmit}>
          <TextInput
            label="Number of Swabs"
            type="number"
            form
            id="numberOfSwabs"
            required
          />
          <Select
            label="Clinic"
            id="clinic"
            name="Clinic"
            form
            variant="outlined"
            options={clinicList}
            onChange={(e) => console.log(e)}
            required
          />

          {loadingLocationsForClinic && (
            <div>
              {' '}
              <div style={{ padding: '25px' }}>Loading Locations</div>
              <div style={{ padding: '25px' }}>
                {' '}
                <CircularProgress />
              </div>
            </div>
          )}

          {locationList && locationList.length && (
            <Select
              label="Location"
              id="location"
              style={{ marginTop: '10px' }}
              variant="outlined"
              options={locationList}
              onChange={setLocation}
              required
            />
          )}

          <Button
            disabled={putOrderLoading}
            label={putOrderLoading ? 'loading' : 'Submit'}
            id="submit"
            type="submit"
            style={{ marginTop: '15px' }}
          />
        </Form>
      )}
    </Modal>
  );
}
