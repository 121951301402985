import React, { useState, useContext, useEffect } from 'react';
import { Paper } from '@mui/material';
import { Button, Modal, Select } from 'Stories';
import { pageSection } from 'Services/MUIStylesOverrides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CircularProgress } from '@mui/material';
// import { ScanKitsBar } from 'Components';
import './index.css';

// import { GET_KITS_IN_BATCH } from './GraphQL/queries.js';

import { FINALIZE_BATCH } from './GraphQL/mutations.js';
import { useLazyQuery, useMutation } from '@apollo/client';

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
};

const statusOptions = [
  { label: 'Created', value: 'created' },
  { label: 'Ready in iLab', value: 'created_ilab_request' },
  { label: 'Ready for Delivery', value: 'ready_for_delivery' },
  { label: 'Delivered', value: 'delivered' },
  { label: 'Processing', value: 'analyzing' },
  { label: 'Results Ready', value: 'completed' },
];

export default function BatchesInfo({
  batch,
  batchKits,
  batchKitsLoading,
  toggleScanKitModal,
  updateBatchStatus,
  updateBatchStatusLoading,
}) {
  const [open, setOpen] = useState(false);
  const [updateStatusModalOpen, setUpdateStatusModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(null);

  const [finalizeBatch] = useMutation(FINALIZE_BATCH, {
    fetchPolicy: 'no-cache',
  });

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const toggleModal = () => {
    setOpen(!open);
  };

  const onSubmitForm = async () => {
    console.log('SUBMITTING BUTTON');

    let resp = await finalizeBatch({
      variables: {
        batchId: batch.id,
      },
    });

    console.log(resp);

    toggleModal();
  };

  const toggleUpdateStatusModal = () => {
    setUpdateStatusModalOpen(!updateStatusModalOpen);
  };

  const onSubmitFormStatusUpdate = async () => {
    const resp = await updateBatchStatus(batch.id, newStatus);

    try {
      toggleUpdateStatusModal();
    } catch (e) {}
  };

  const openToiLab = () => {
    // console.log(batch);
    window.open(
      'https://ua.ilab.agilent.com/sc/4837/arizona-molecular-clinical-core?tab=requests&sid=' +
        batch.ilabRequestId,
      '_blank'
    );
  };

  const BatchKitsCards = ({ kits }) => {
    return (
      <>
        {kits.length > 0 ? (
          kits.map((kit, index) => {
            const { kitId } = kit;
            return (
              <Paper
                sx={{
                  ...pageSection,
                  width: '30%',
                  maxWidth: '340px',
                  minWidth: '250px',
                  height: '40px',
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 1,
                  fontSize: '0.875rem',
                  overflowY: 'hidden',
                }}
                // className="BatchKits_Card"
                key={`batch-kit-${index}`}
              >
                <p>{kitId}</p>
                <FontAwesomeIcon icon={solid('check')} />
              </Paper>
            );
          })
        ) : (
          <p>No Kits Assigned</p>
        )}
      </>
    );
  };

  return (
    <div>
      <Paper
        sx={{
          ...pageSection,
          height: '100%',
        }}
      >
        {batch ? (
          <div style={{ padding: '25px' }}>
            <div
              className="header-container"
              style={{ padding: 0, paddingBottom: '20px' }}
            >
              <h2 className="clinic-info-header">{'Batch #' + batch.id}</h2>
              {batch.status === 'created' && (
                <Button
                  style={{
                    borderRadius: 30,
                    width: '255px',
                  }}
                  label="Create iLab Request"
                  onClick={toggleModal}
                />
              )}
              {batch.ilabRequestId && (
                <Button
                  style={{
                    borderRadius: 30,
                    width: '255px',
                    backgroundColor: '#3dc1eb',
                  }}
                  label="Open in iLab"
                  onClick={openToiLab}
                />
              )}
            </div>
            <div>
              <div className="OrderPanel_QuickInfoHolder">
                <div># of Swabs</div>
                <div>Created By</div>
                <div>Cost</div>
                <div>iLab Request Made</div>
                <div>Status</div>
              </div>
              <div className="OrderPanel_QuickInfoHolder OrderPanel_Data">
                <div>{batchKits.length}</div>
                <div>{batch.orderedBy}</div>
                <div>{USDollar.format(2000)}</div>
                <div>{batch.ilabRequestId ? 'Yes' : 'No'}</div>
                <div>
                  {batch.displayStatus}
                  <FontAwesomeIcon
                    icon={solid('pen-to-square')}
                    size="lg"
                    style={{ marginLeft: '20px', cursor: 'pointer' }}
                    onClick={toggleUpdateStatusModal}
                  />
                </div>
              </div>
            </div>
            {/* Kits Assigned */}
            <div>
              <p
                style={{
                  color: 'var(--primaryDark)',
                  margin: '25px 0 10px 0',
                  fontWeight: 700,
                }}
              >
                Kits
              </p>
              <div className="BatchKits_SectionHolder">
                {batchKitsLoading ? (
                  <CircularProgress />
                ) : (
                  <BatchKitsCards kits={batchKits} />
                )}
              </div>
            </div>
            {batch.status === 'created' && (
              <div>
                <div
                  className="StatusRow_Holder"
                  style={{
                    backgroundColor:
                      batch.status === 'pending' ? '#79807f' : 'var(--primaryDark)',
                  }}
                  onClick={() => toggleScanKitModal(batchKits)}
                >
                  <div style={{ display: 'flex' }}>
                    <div className="StatusIcon_Holder">
                      <FontAwesomeIcon
                        icon={solid('vial-circle-check')}
                        style={{
                          color:
                            batch.status === 'pending' ? '#79807f' : 'var(--primaryDark)',
                          fontSize: '22px',
                        }}
                      />
                    </div>
                    <div className="StatusRow_Label">
                      <div className="StatusRow_Label_Text">Assign Swabs</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Modal
              open={updateStatusModalOpen}
              toggle={toggleUpdateStatusModal}
              title="Update Batch Status"
            >
              <div style={{ padding: '30px 0px' }}>
                This batch's current status is <b>{batch.displayStatus}</b>.
              </div>

              <Select
                options={statusOptions}
                value={batch.status}
                onChange={(e) => {
                  setNewStatus(e);
                }}
                style={{ marginBottom: '30px' }}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={buttonStyle}
                  label="Cancel"
                  onClick={toggleUpdateStatusModal}
                />
                <Button
                  style={{
                    ...buttonStyle,
                    marginLeft: '20px',
                    backgroundColor: !newStatus ? 'grey' : '#3dc1eb',
                  }}
                  label="Update"
                  disabled={!newStatus}
                  onClick={onSubmitFormStatusUpdate} //update status query
                />
              </div>
            </Modal>
          </div>
        ) : (
          <div className="select-clinic-message">Select Batch</div>
        )}
      </Paper>
      <Modal open={open} toggle={() => toggleModal()} title={'Finalize Batch?'}>
        <div style={{ padding: '30px 0px' }}>
          Making an iLab request will finalize this batch and lock it from any
          editing. Are you sure you want to continue?
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button style={buttonStyle} label="Cancel" onClick={toggleModal} />
          <Button
            style={{ ...buttonStyle, marginLeft: '20px' }}
            label="Create iLab Request"
            onClick={onSubmitForm}
          />
        </div>
      </Modal>
    </div>
  );
}
