import { Sort } from '@mui/icons-material';
export const searchBarContainer = {
  backgroundColor: '#031e45',
  borderRadius: 30,
  color: 'white',
  borderStyle: 'none',
};

export const searchIcon = {
  position: 'relative',
  top: 10,
  zIndex: 2,
  color: 'white',
  marginTop: 16,
  marginLeft: 11,
};

export const blueSearchBar = {
  '& .MuiFilledInput-root': {
    backgroundColor: '#031e45',
    borderRadius: 30,
    color: 'white',
    borderStyle: 'none',
    padding: 0,
  },
  '& .Mui-focused': { backgroundColor: '#031e45 !important' },
  '& .MuiFilledInput-root:hover': { backgroundColor: '#031e45 !important' },
  '& .MuiFilledInput-root:focus': { backgroundColor: '#031e45 !important' },
  '& .MuiFilledInput-root > input': {
    marginLeft: 3,
  },
  '& .MuiFilledInput-underline:before': {
    borderStyle: 'none !important',
  },
  '& .MuiFilledInput-underline:after': {
    borderStyle: 'none !important',
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderStyle: 'none',
    backgroundColor: 'none',
  },
  '& .MuiInputLabel-filled': {
    color: 'white',
    marginLeft: 3,
    backgroundColor: 'none',
  },
  '& .MuiInputBase-root-MuiFilledInput-root:hover': {
    background: '#031e45 !important',
  },
};

export const modalText = {
  top: -40,
  marginBottom: -25,
  '& .MuiFilledInput-root': {
    backgroundColor: '#031e45',
    borderRadius: 30,
    color: 'white',
    borderStyle: 'none',
  },
  '& .Mui-focused': { backgroundColor: '#031e45 !important' },
  '& .MuiFilledInput-root:hover': { backgroundColor: '#031e45 !important' },
  '& .MuiFilledInput-root:focus': { backgroundColor: '#031e45 !important' },
  '& .MuiFilledInput-root > input': {
    marginLeft: 3,
  },
  '& .MuiFilledInput-underline:before': {
    borderStyle: 'none !important',
  },
  '& .MuiFilledInput-underline:after': {
    borderStyle: 'none !important',
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderStyle: 'none',
    backgroundColor: 'none',
  },
  '& .MuiInputLabel-filled': {
    color: 'white',
    marginLeft: 3,
    backgroundColor: 'none',
  },
  '& .MuiInputBase-root-MuiFilledInput-root:hover': {
    background: '#031e45 !important',
  },
};

export const tooltip = {
  backgroundColor: 'white',
  color: 'black',
  borderRadius: 2,
  fontSize: 12,
  fontWeight: 600,
  boxShadow: '0 0 5px rgba(0,0,0,0.3)',
};

export const arrow = {
  backgroundColor: 'transparent',
  color: 'white',
  boxShadow: 'none',
};

export const accordion = {
  '&::before': {
    display: 'none',
  },
  '&&.MuiPaper-elevation': {
    boxShadow: 'none !important',
  },
};

export const accordionSummary = {
  backgroundColor: '#eaeaea',
  '&:hover': {
    backgroundColor: '#dadada',
  },
  padding: 1,
  margin: 1,
  boxShadow: 'none !important',
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: 18,
};

export const dialog = {
  '& .MuiDialog-paper': {
    borderRadius: 5,
  },
  '& .MuiPaper-root': {
    width: '100% !important',
    // height: '100% !important',
  },
};

export const paper = {
  p: 2,
  mb: 2,
  display: 'grid',
  backgroundColor: '#eaeaea',
  cursor: 'pointer',
  boxShadow: 'none !important',
  '&:hover': {
    backgroundColor: '#dadada',
  },
  fontWeight: 500,
  fontSize: 18,
};

export const sortIcons = {
  desc: <Sort style={{ float: 'right', fontSize: 16 }} />,
  asc: (
    <Sort
      style={{
        float: 'right',
        transform: 'rotate(180deg) scaleX(-1)',
        fontSize: 16,
      }}
    />
  ),
};

export const sortContainer = {
  p: 2,
  mb: 2,
  display: 'grid',
  backgroundColor: '#031e45',
  color: 'white',
};

export const sortButton = {
  '&&.MuiButton-root': {
    color: 'white',
    textTransform: 'capitalize',
    height: 20,
    width: '40%',
  },
};

export const pageSection = {
  borderRadius: 5,
  boxShadow: '0px 0px 6px #aaaaaa',
  margin: 2,
  padding: 2,
};

export const lockedButton = {
  '&.Mui-disabled': {
    backgroundColor: '#dcdcdc !important',
    color: 'white !important',
  },
};
