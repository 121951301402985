import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';

import { TextInput, Button, theme } from 'Stories';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import './Style.scss';

export default function MultiAdd(props) {
  const [value, setValue] = useState(null);
  const [variable, setVariable] = useState(null);
  const [scoreValue, setScoreValue] = useState(null);
  const [values, setValues] = useState(
    props.stateValue ? props.stateValue : []
  );
  const [hiddenFieldInfo, setHiddenFieldInfo] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleOnChange = (e) => {
    setValue(e);
  };

  const handleVariableChange = (e) => {
    setVariable(e);
  };

  const handleScoreValueChange = (e) => {
    setScoreValue(e);
  };

  useEffect(() => {
    if (props.stateValue) setValues(props.stateValue);
  }, [props.stateValue]);

  const submitValue = () => {
    let valuesDupe = [...values];

    if (editingIndex !== null) {
      valuesDupe[editingIndex] = { value: value, id: uuidv4() };
    } else {
      valuesDupe.push({
        value: value,
        id: uuidv4(),
        variable,
        scoreValue: parseInt(scoreValue),
      });
    }

    console.log('SETTING: ', value, variable, scoreValue);

    setValues(valuesDupe);
    setValue('');
    setScoreValue('');
    setVariable('');
    setEditingIndex(null);
  };

  useEffect(() => {
    props.setValue(props.name ? props.name : props.id, JSON.stringify(values));
  }, [values]);

  const deleteItem = (index) => {
    let valuesDupe = [...values];
    valuesDupe.splice(index, 1);

    setValues(valuesDupe);
  };

  const registration = props.register
    ? props.register(props.name ? props.name : props.id)
    : null;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', marginBottom: '15px' }}>
        <TextInput
          label={props.label ? props.label : 'NO-LABEL-PROVIDED'}
          id={props.id ? 'TextBar_' + props.id : 'NO-ID-PROVIDED'}
          variant="outlined"
          value={value}
          // inputProps={{ maxlength: 100 }}
          onChange={handleOnChange}
          onEnterListener={(e) => submitValue()}
          style={{ marginRight: '5px', flex: 1 }}
        />
        <TextInput
          label={'Variable'}
          id={props.id ? 'VariableBar_' + props.id : 'NO-ID-PROVIDED'}
          variant="outlined"
          value={variable}
          // inputProps={{ maxlength: 100 }}
          onChange={handleVariableChange}
          onEnterListener={(e) => submitValue()}
          style={{ marginRight: '5px', flex: 1 }}
        />
        <TextInput
          label={'Value'}
          id={props.id ? 'ValueBar_' + props.id : 'NO-ID-PROVIDED'}
          variant="outlined"
          value={scoreValue}
          type="number"
          // inputProps={{ maxlength: 100 }}
          onChange={handleScoreValueChange}
          onEnterListener={(e) => submitValue()}
          style={{ marginRight: '5px', flex: 1 }}
        />
        <Button
          onClick={(e) => submitValue()}
          style={{ backgroundColor: theme.palette.general.secondary.navy }}
          label={
            <FontAwesomeIcon
              icon={solid('turn-down')}
              size="xl"
              style={{ rotate: '90deg' }}
            />
          }
        />
        <input
          id={props.id ? props.id : 'NO-ID-PROVIDED'}
          type="text"
          defaultValue={values?.toString()}
          style={{ display: 'none' }}
          {...registration}
          name={props.name}
        />
      </div>
      <div className="answerMapper">
        {values?.map((v, index) => {
          return (
            <div
              className="singleItem_MultiAdd"
              style={{
                color: theme.palette.general.secondary.navy,
              }}
            >
              <FontAwesomeIcon
                icon={solid('circle-minus')}
                size="md"
                style={{ marginRight: '10px', cursor: 'pointer' }}
                onClick={() => {
                  deleteItem(index);
                }}
              />
              <div
                onClick={() => {
                  setEditingIndex(index);
                  setValue(v.value);
                  setScoreValue(JSON.stringify(v.scoreValue));
                  setVariable(v.variable);
                }}
                style={{ marginRight: '10px', cursor: 'pointer' }}
              >
                {v.value}
                {v.variable ? `(${v.variable + ': ' + v.scoreValue})` : ''}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

TextInput.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

TextInput.defaultProps = {
  id: 'NO-ID-PROVIDED',
  variant: 'filled',
  onClick: undefined,
  type: null,
};
