import { gql } from '@apollo/client';

export const GENERATE_KITS = gql`
  mutation ($info: GenerateKitInput) {
    generateKits(info: $info) {
      status
      body
    }
  }
`;

export const CREATE_KIT = gql`
  mutation ($info: CreateKitInput) {
    createKit(info: $info) {
      status
      body
    }
  }
`;

export const PUT_KIT_STATUS = gql`
  mutation ($info: KitStatusInput) {
    putKitStatus(info: $info) {
      status
      body
    }
  }
`;

export const BATCH_CREATE_KITS = gql`
  mutation ($kitIds: [String], $clinic: String) {
    batchCreateKits(kitIds: $kitIds, clinic: $clinic) {
      status
      body
    }
  }
`;
