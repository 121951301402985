const IS_PROD = window.location.host === 'inventory.healaz.com';

export default {
  region: 'us-west-2',
  userPoolId: IS_PROD ? 'us-west-2_zGyEKuwWG' : 'us-west-2_HPBuJc5lR',
  userPoolWebClientId: IS_PROD
    ? '68tqfs26keu146ouu1afe2nptv'
    : '3vts1v4ahr8ghdp61vcb9kni8v',
  graphqlApiEndpoint: IS_PROD
    ? 'https://7dy4sqvb6vatxph53mfppjuwuy.appsync-api.us-west-2.amazonaws.com/graphql'
    : 'https://w4iucg2albbmjbwdy7ha7yyyzi.appsync-api.us-west-2.amazonaws.com/graphql',
  authType: 'AMAZON_COGNITO_USER_POOLS',
};
