import { gql } from '@apollo/client';

export const LIST_CLINICS = gql`
  query ($info: PaginationInput) {
    listClinics(info: $info) {
      status
      body
    }
  }
`;

