import React, { useState, useEffect } from 'react';

import './Home.css';
import { useNavigate } from 'react-router-dom';

import { useWindowDimensions } from 'Services/CustomHooks';

import { Auth } from 'aws-amplify';

import { LIST_CLINICS } from './GraphQL/queries.js';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Page } from 'Stories';
import { OrdersSection, KitsSection } from './homeComponents';
import { ClinicsTable } from 'Components';

export default function Home(props) {
  let navigate = useNavigate();

  const [clinics, setClinics] = useState([]);
  const [kits, setKits] = useState([]);
  const [page, setPage] = useState(0);
  const [user, setUser] = useState({});

  const initUser = async () => {
    let session = (await Auth.currentSession()).getIdToken().payload;
    setUser(session);
  };

  useEffect(() => {
    getClinics();
    initUser();
  }, []);

  const [
    listClinics,
    { loading: isClinicsLoading, data: taskData, error: taskError },
  ] = useLazyQuery(LIST_CLINICS, {
    fetchPolicy: 'no-cache',
  });

  const getClinics = async () => {
    let resp = await listClinics({
      variables: {
        info: {
          page: page,
          limit: 10,
        },
      },
    });

    let data = JSON.parse(resp.data.listClinics.body);

    for (var i = 0; i < data.length; ++i) {
      data[i].active = data[i].active === false ? 'No' : 'Yes';
      data[i].actions = {
        edit: true,
        delete: false,
      };
    }

    console.log('SET DATA: ', data);

    setClinics(data);
    console.log('RESP: ', resp.data);
  };

  const rowOnClick = (clinic) => {
    navigate('/clinics', { state: { selectedClinic: clinic } });
  };

  return (
    <Page pageTitle={`Welcome, ${user.given_name}`}>
      <div className={'main-container'}>
        <ClinicsTable
          data={clinics}
          loading={isClinicsLoading}
          rowOnClick={rowOnClick}
        />
        <div>
          <OrdersSection />
          <KitsSection />
        </div>
      </div>
    </Page>
  );
}
