import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { COUNT_CLINIC_KITS, GET_KIT_STATUS_ALERTS } from '../GraphQL/queries';
import { Paper } from '@mui/material';

import './Style.css';
import { pageSection } from 'Services/MUIStylesOverrides';

import Lottie from 'lottie-react';
import loadingGif from 'Assets/dna_loader.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import ViewModal from './ViewModal';
import { Button } from 'Stories';

const STATUS_ORDER = [
  'available',
  'registered',
  'received',
  'analyzing',
  'completed',
];

const STATUS_TRANSLATOR = {
  available: 'Available',
  registered: 'Registered',
  received: 'At the Lab',
  analyzing: 'Analyzing',
  completed: 'Completed',
};

export default function PGxHighlightNumber({ id, database_name }) {
  const [kits, setKits] = useState({});
  const [alerts, setAlerts] = useState({});

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  let navigate = useNavigate();

  const [countClinicKits, { loading: kitsLoading }] = useLazyQuery(
    COUNT_CLINIC_KITS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const toggleModal = (data) => {
    if (!open) {
      setModalData(data);
    } else {
      setModalData({});
    }

    setOpen(!open);
  };

  const [getKitStatusAlerts, { loading: kitStatusAlertsLoading }] =
    useLazyQuery(GET_KIT_STATUS_ALERTS, {
      fetchPolicy: 'no-cache',
    });

  const getKits = async () => {
    let resp = await countClinicKits({
      variables: {
        clinicId: id,
        clinicRef: database_name,
      },
    });
    let statuses = JSON.parse(resp.data.countClinicKits.body);
    setKits(statuses);
  };

  const getAlerts = async () => {
    let resp = await getKitStatusAlerts({
      variables: {
        clinicRef: database_name,
      },
    });
    let statuses = JSON.parse(resp.data.getKitStatusAlerts.body);
    console.log('HIT: ', statuses);

    let alerts = {
      available: [],
      registered: [],
      received: [],
      analyzing: [],
      completed: [],
    };

    for (var i = 0; i < statuses.length; ++i) {
      alerts[statuses[i].status].push(statuses[i]);
    }

    setAlerts(alerts);
  };

  useEffect(() => {
    if (id) {
      getKits();
      getAlerts();
    }
  }, [id]);

  return (
    <div style={{ margin: 16 }}>
      <div
        className="Home_CardTitle"
        style={{ boxShadow: '0px 0px 6px #aaaaaa' }}
      >
        Kits
      </div>
      <div className="HomePatientTable_Section">
        <div style={{ padding: 20 }}>
          {kitsLoading ? (
            <div>
              <Lottie
                style={{
                  width: '60%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  transform: 'rotate(20deg)',
                  maxWidth: 200,
                }}
                animationData={loadingGif}
                loop={true}
              />
            </div>
          ) : (
            <div>
              {kits &&
                STATUS_ORDER.map((stat, index) => {
                  let hasAlerts = alerts[stat] && alerts[stat].length > 0;
                  return (
                    <div
                      className="KitStat_Container"
                      style={{
                        cursor: hasAlerts ? 'pointer' : 'default',
                      }}
                      onClick={() => {
                        if (hasAlerts) {
                          toggleModal({
                            alarms: alerts[stat],
                            name:
                              'Outstanding Kits in ' + STATUS_TRANSLATOR[stat],
                          });
                        }
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="KitStat_Number">{kits[stat]}</div>
                        <div>
                          <div className="KitStat_MainText">
                            {STATUS_TRANSLATOR[stat]}
                          </div>
                          {hasAlerts && (
                            <div className="KitStat_View">
                              {alerts[stat].length} kits in status for more than
                              7 days
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        {hasAlerts && (
                          <FontAwesomeIcon
                            icon={solid('triangle-exclamation')}
                            style={{
                              color: `var(--accentPrimary)`,
                              fontSize: '2.4em',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <Button
        style={{
          borderRadius: 30,
          width: '100%',
          textTransform: 'none',
        }}
        label="Search All Kits"
      />
      <ViewModal open={open} toggle={toggleModal} data={modalData} />
    </div>
  );
}
