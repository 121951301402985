import { gql } from '@apollo/client';

export const COUNT_CLINIC_PATIENTS = gql`
  query ($clinicId: String) {
    countClinicPatients(clinicId: $clinicId) {
      status
      body
    }
  }
`;

export const COUNT_CLINIC_KITS = gql`
  query ($clinicId: String, $clinicRef: String) {
    countClinicKits(clinicId: $clinicId, clinicRef: $clinicRef) {
      status
      body
    }
  }
`;

export const LIST_ORDERS_BY_CLINIC = gql`
  query ($info: PaginationInput, $clinicId: String) {
    listOrdersByClinic(info: $info, clinicId: $clinicId) {
      status
      body
    }
  }
`;
