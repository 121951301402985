import Box from '@mui/material/Box';
import { pageSection } from 'Services/MUIStylesOverrides';

export default function StyledBox({ children }) {
  return (
    <Box
      sx={{
        ...pageSection,
        backgroundColor: 'white',
        padding: '25px',
      }}
    >
      {children}
    </Box>
  );
}