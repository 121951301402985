import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { dialog } from 'Services/MUIStylesOverrides.js';

import { Close } from '@mui/icons-material';

import './Style.css';
import { SearchBar, TableCard } from 'Stories';
import { LIST_ORDERS_BY_CLINIC } from '../GraphQL/queries';
import { useLazyQuery } from '@apollo/client';

const TABLE_HEADERS = [
  {
    name: 'Order ID',
    key: 'id',
    sortable: true,
  },
  {
    name: 'Number of Swabs',
    key: 'numberOfSwabs',
    size: 'small',
    sortable: true,
  },
  {
    name: 'Date',
    key: 'date',
    size: 'small',
    sortable: true,
  },
  {
    name: 'Status',
    key: 'status',
    size: 'small',
    sortable: true,
  },
];

export default function Main({ open, toggle, id }) {
  const [displayedData, setDisplayedData] = useState([]);
  const [rawData, setRawData] = useState([]);

  let navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [listConditions, setListConditions] = useState({
    sortDirection: 'asc',
    sortProperty: 'name',
    page: 0,
  });

  const [listOrdersByClinic, { loading: clinicOrdersLoading }] = useLazyQuery(
    LIST_ORDERS_BY_CLINIC,
    {
      fetchPolicy: 'no-cache',
    }
  );

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getOrders = async () => {
    let resp = await listOrdersByClinic({
      variables: {
        clinicId: id,
      },
    });
    let ord = JSON.parse(resp.data.listOrdersByClinic.body);

    for (var i = 0; i < ord.length; ++i) {
      ord[i].status = capitalizeFirstLetter(ord[i].status);
    }

    console.log(ord);

    setRawData(ord);
    setDisplayedData(ord);
  };

  useEffect(() => {
    if (id) {
      getOrders();
    }
  }, [id]);

  const handleSearch = () => {
    if (search && search.length > 0) {
      let arr = [];

      for (var i = 0; i < rawData.length; ++i) {
        let x = rawData[i];
        if (x.orderId.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          arr.push(x);
        }
      }

      setDisplayedData(arr);
    } else {
      setDisplayedData([...rawData]);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  const triggerSortSearch = (property, order) => {
    setDisplayedData([]);
    setListConditions({
      ...listConditions,
      page: 0,
      sortDirection: order,
      sortProperty: property,
    });
  };

  const rowOnClick = (order) => {
    navigate('/orders/' + order.id);
  };

  return (
    <div>
      <Dialog open={open} minWidth={'lg'} maxWidth={'lg'} sx={dialog}>
        <DialogTitle sx={{ padding: 0 }}>
          <div className="KitAlarmCardTitle" style={{ fontSize: '1.5em' }}>
            Orders
            <IconButton
              style={{ float: 'right', color: 'white' }}
              onClick={toggle}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: 24 }}>
            <SearchBar
              placeholder={'Search Orders by ID'}
              value={search}
              onEnterListener={handleSearch}
              onChange={(e) => setSearch(e)}
            />
            <div style={{ height: '25px' }}> </div>
            <TableCard
              data={displayedData && displayedData.length ? displayedData : []}
              headers={TABLE_HEADERS}
              loading={!displayedData}
              infiniteScroll={false}
              onRowClick={rowOnClick}
              searchActive={search}
              sortable
              sortDefault="daysInStatus"
              sortTrigger={(p, o) => triggerSortSearch(p, o)}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
