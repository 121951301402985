import { useState, useEffect } from 'react';
import { SearchBar, TableCard } from 'Stories';

import moment from 'moment';

import './Style.css';
import { useLazyQuery } from '@apollo/client';

import { COUNT_CLINICS, LIST_CLINICS } from '../GraphQL/queries';

const TABLE_HEADERS = [
  {
    name: 'Clinic',
    key: 'name',
    sortable: true,
  },
  {
    name: 'Kits',
    key: 'numberOfKits',
    size: 'small',
    sortable: true,
  },
];

const LIMIT = 25;

export default function Main({ rowOnClick, loading }) {
  const [search, setSearch] = useState('');

  const [displayedData, setDisplayedData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState([]);

  const [listConditions, setListConditions] = useState({
    sortDirection: 'asc',
    sortProperty: 'name',
    page: 0,
  });

  const [page, setPage] = useState(0);

  const [countClinics, { loading: patientCountLoading }] = useLazyQuery(
    COUNT_CLINICS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [
    listClinics,
    { loading: isClinicsLoading, data: taskData, error: taskError },
  ] = useLazyQuery(LIST_CLINICS, {
    fetchPolicy: 'no-cache',
  });

  const loadData = async () => {
    let resp = await listClinics({
      variables: {
        info: {
          page: page,
          limit: 10,
        },
      },
    });

    let data = JSON.parse(resp.data.listClinics.body);

    console.log(data);

    for (var i = 0; i < data.length; ++i) {
      data[i].active = data[i].active === false ? 'No' : 'Yes';
      data[i].actions = {
        edit: true,
        delete: false,
      };
    }
    setDisplayedData(data);
  };

  const loadCount = async () => {
    // let resp = await countClinics();
    // let raw = JSON.parse(resp.data.countClinics.body).count;
    setTotalRowCount(5);
  };

  useEffect(() => {
    loadData();
    loadCount();
  }, []);

  const handleSearch = () => {
    setDisplayedData([]);
    setListConditions({
      ...listConditions,
      page: 0,
      searchTerm: search,
    });
  };

  const triggerSortSearch = (property, order) => {
    setDisplayedData([]);
    setListConditions({
      ...listConditions,
      page: 0,
      sortDirection: order,
      sortProperty: property,
    });
  };

  const loadNextPage = () => {
    setListConditions({
      ...listConditions,
      page: listConditions.page + 1,
    });
  };

  useEffect(() => {
    loadData();
  }, [listConditions]);

  return (
    <div style={{ padding: 24 }}>
      <SearchBar
        placeholder={'Search Patient by Name'}
        value={search}
        onEnterListener={handleSearch}
        onChange={(e) => setSearch(e)}
      />
      <div style={{ height: '25px' }}> </div>
      <TableCard
        data={displayedData}
        headers={TABLE_HEADERS}
        loading={loading && displayedData.length === 0}
        onRowClick={(e) => {
          rowOnClick(e);
        }}
        infiniteScroll={true}
        loadMoreHandler={loadNextPage}
        totalRowCount={totalRowCount}
        searchActive={search}
        sortable
        sortDefault="displayName"
        sortTrigger={(p, o) => triggerSortSearch(p, o)}
      />
    </div>
  );
}
