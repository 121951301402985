import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import {
  LIST_ORDERS_BY_CLINIC,
  LIST_OUTSTANDING_ORDERS_BY_CLINIC,
} from '../GraphQL/queries';
import { Paper } from '@mui/material';

import ViewModal from './ViewModal';

import './Style.css';
import { pageSection } from 'Services/MUIStylesOverrides';

import Lottie from 'lottie-react';
import loadingGif from 'Assets/dna_loader.json';
import { Button, TableCard } from 'Stories';
import moment from 'moment';

const TABLE_HEADERS = [
  {
    name: 'Order ID',
    key: 'id',
    sortable: true,
  },
  {
    name: 'Status',
    key: 'status',
    size: 'small',
    sortable: true,
  },
  {
    name: 'Days',
    key: 'daysInStatus',
    size: 'small',
    sortable: true,
  },
];

export default function PGxHighlightNumber({ id, database_name }) {
  const [open, setOpen] = useState(false);
  const [outstandingOrders, setOutstandingOrders] = useState([]);

  let navigate = useNavigate();

  const [
    listOutstandingOrdersByClinic,
    { loading: clinicOutstandingOrdersLoading },
  ] = useLazyQuery(LIST_OUTSTANDING_ORDERS_BY_CLINIC, {
    fetchPolicy: 'no-cache',
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getOutstandingOrders = async () => {
    let resp = await listOutstandingOrdersByClinic({
      variables: {
        clinicId: id,
      },
    });

    let ord = JSON.parse(resp.data.listOutstandingOrdersByClinic.body);

    for (var i = 0; i < ord.length; ++i) {
      ord[i].status = capitalizeFirstLetter(ord[i].status);
      ord[i].daysInStatus = moment().diff(moment(ord[i].date), 'days');
    }

    setOutstandingOrders(ord);
  };

  useEffect(() => {
    if (id) {
      getOutstandingOrders();
    }
  }, [id]);

  const rowOnClick = (order) => {
    navigate('/orders/' + order.id);
  };

  return (
    <Paper
      sx={{
        ...pageSection,
        height: 'fit-content',
        padding: '20px',
      }}
      className="HomePatientTable_Section"
    >
      <div className="Home_CardTitle">Outstanding Orders</div>
      <div style={{ padding: 20 }}>
        {clinicOutstandingOrdersLoading ? (
          <div>
            <Lottie
              style={{
                width: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
                transform: 'rotate(20deg)',
                maxWidth: 200,
              }}
              animationData={loadingGif}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <TableCard
              data={outstandingOrders}
              headers={TABLE_HEADERS}
              loading={clinicOutstandingOrdersLoading}
              onRowClick={rowOnClick}
            />
            <div className="PatientCard_ButtonHolder">
              <Button
                style={{
                  borderRadius: 30,
                  minWidth: '255px',
                  width: '80%',
                  backgroundColor: 'var(--primaryLight)',
                }}
                label={'Create New Order'}
                // onClick={toggleModal}
              />
              <Button
                style={{
                  borderRadius: 30,
                  minWidth: '255px',
                  width: '80%',
                  marginTop: '15px',
                  backgroundColor: 'var(--primaryDark)',
                }}
                label={'View All Orders'}
                onClick={() => {
                  setOpen(!open);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <ViewModal
        id={id}
        open={open}
        toggle={() => {
          setOpen(!open);
        }}
      />
    </Paper>
  );
}
