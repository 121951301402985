import React, { useState, useContext, useEffect } from 'react';
import { Box, Table, Modal } from 'Stories';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import PageForm from './PageForm.js';

import './Style.css';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  LIST_GENE_INTERACTIONS,
  COUNT_GENE_INTERACTIONS,
} from './GraphQL/queries';

const labels = [
  {
    alignment: 'left',
    label: 'Gene',
    key: 'gene',
  },
  {
    alignment: 'left',
    label: 'Genotype',
    key: 'genotype',
  },
  {
    alignment: 'left',
    label: 'Category',
    key: 'category',
  },
  {
    alignment: 'left',
    label: 'Evidence Level',
    key: 'evidence_level',
  },
  {
    alignment: 'left',
    label: 'Drug Class',
    key: 'drug_class',
  },
  {
    alignment: 'right',
    label: 'Actions',
    key: 'actions',
    size: 'small',
  },
];

export default function Main(props) {
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(1);
  const [modalInfo, setModalInfo] = useState({});
  const [totalCount, setTotalCount] = useState(0);

  const [open, setOpen] = useState(false);

  const [listGeneInteractions, { loading: geneInteractionsLoading }] =
    useLazyQuery(LIST_GENE_INTERACTIONS, {
      fetchPolicy: 'no-cache',
    });

  const [countGeneInteractions, { loading: geneCountLoading }] = useLazyQuery(
    COUNT_GENE_INTERACTIONS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  function capitalizeWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const countItems = async () => {
    const resp = await countGeneInteractions();
    let c = JSON.parse(resp.data.countGeneInteractions.body).count;
    let tp = Math.ceil(c / pageLimit);

    setTotalPages(tp);
    setTotalCount(c);
  };

  useEffect(() => {
    getItems();
  }, [page]);

  const getItems = async () => {
    const resp = await listGeneInteractions({
      variables: {
        info: {
          page: page,
          limit: pageLimit,
        },
      },
    });

    const { status, body } = resp.data.listGeneInteractions;
    let rawBody = JSON.parse(body);

    for (var i = 0; i < rawBody.length; ++i) {
      rawBody[i].actions = { edit: true };
    }

    setData(rawBody);
  };

  useEffect(() => {
    getItems();
    countItems();
  }, []);

  const toggleModal = (e) => {
    setModalInfo(e ? e : {});
    setOpen(!open);
  };

  const onClickEdit = (e) => {
    toggleModal(e);
  };

  const handleChangePage = (e, v) => {
    setPage(v - 1);
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>Gene Interactions</div>
      </div>

      <Table
        labels={labels}
        data={data}
        loading={geneInteractionsLoading}
        onClickEdit={onClickEdit}
        capitalizeWord={capitalizeWord}
        pagination={true}
        totalPages={totalPages}
        page={page + 1}
        handleChangePage={handleChangePage}
      />

      <Modal
        open={open}
        toggle={(e) => toggleModal(e)}
        title={`Manage Interaction`}
      >
        <PageForm modalInfo={modalInfo} toggleModal={(e) => toggleModal(e)} />
      </Modal>
    </Box>
  );
}
