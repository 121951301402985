import { gql } from '@apollo/client';

export const PUT_ORDER = gql`
  mutation ($info: OrderInfo) {
    putOrder(info: $info) {
      status
      body
    }
  }
`;

export const PUT_ORDER_STATUS = gql`
  mutation ($orderId: String, $status: String) {
    putOrderStatus(orderId: $orderId, status: $status) {
      status
      body
    }
  }
`;

export const ADD_KITS_TO_INVENTORY = gql`
  mutation ($lotNumber: String, $number: Int) {
    addKitsToInventory(lotNumber: $lotNumber, number: $number) {
      status
      body
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation ($info: UpdateOrderInfo) {
    updateOrder(info: $info) {
      status
      body
    }
  }
`;
