import React, { useEffect, useState } from 'react';

import { Auth } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';

import './Style.css';

import SignIn from './SignIn';
import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import VerificationCode from './VerificationCode';
import MFA from './MFA';

import sidePanelImage from 'Assets/Logo/Color_300ppi.png';
import logo from 'Assets/Logo/Color_150ppi.png';
import wave from 'Assets/Gradient Wave.svg';

import { useCookies } from 'react-cookie';

import Lottie from 'lottie-react';
import initalLoadingAnimation from 'Assets/initalLoadingAnimation.json';

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ApolloProvider,
  HttpLink,
} from '@apollo/client';

import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import awsmobile from 'aws-exports';

const cookiesToHold = [
  'family_name',
  'given_name',
  'pageAccess',
  'email',
  'username',
];

const Authentication = function (props) {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  const [authStatus, setAuthStatus] = useState(
    Auth.user && Auth.user.username ? 'authenticated' : 'unauthenticated'
  );
  const [error, setError] = useState(null);
  const [authSession, setAuthSession] = useState({});
  const [loading, setLoading] = useState(true);

  const [isFading, setIsFading] = useState(false);

  const [animationOpacity, setAnimationOpacity] = useState(1);
  const [divOpacity, setDivOpacity] = useState(0);

  const [heldPassword, setHeldPassword] = useState(null);
  const [changePasswordType, setChangePasswordType] = useState(null);

  const clearError = () => {
    setError(null);
  };

  const delayLoad = () => {
    setTimeout(() => {
      if (Auth.user && Auth.user.username) {
        setAuthStatus('authenticated');
      }
      setIsFading(true);
      setLoading(false);
    }, 1200);
  };

  useEffect(() => {
    if (Auth.user && Auth.user.username) {
      setAuthStatus('authenticated');
    }
  }, [Auth.user && Auth.user.username]);

  const readyToChangePassword = () => {
    setChangePasswordType('forgotPassword');
    setAuthStatus('changePassword');
  };

  const listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        setAuthStatus('authenticated');
        console.log('user signed in');
        break;
      case 'signIn_failure':
        setAuthStatus('unauthenticated');
        setError('Username or password is incorrect');
        console.log('user sign in failed');
        break;
      case 'signUp':
        console.log('user signed up');
        break;
      case 'signUp_failure':
        console.log('user sign up failed');
        break;
      case 'confirmSignUp':
        console.log('user confirmation successful');
        break;
      case 'completeNewPassword_failure':
        console.log('user did not complete new password flow');
        break;
      case 'autoSignIn':
        console.log('auto sign in successful');
        break;
      case 'autoSignIn_failure':
        console.log('auto sign in failed');
        break;
      case 'forgotPassword':
        setAuthStatus('forgotPassword');
        console.log('password recovery initiated');
        break;
      case 'forgotPassword_failure':
        console.log('password recovery failed');
        break;
      case 'forgotPasswordSubmit':
        setAuthStatus('unauthenticated');
        console.log('password confirmation successful');
        break;
      case 'forgotPasswordSubmit_failure':
        setError('There was an issue resetting your password');
        console.log('password confirmation failed');
        break;
      case 'verify':
        console.log('TOTP token verification successful');
        break;
      case 'tokenRefresh':
        setAuthStatus('authenticated');
        console.log('token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        Logout();
        console.log('token refresh failed');
        break;
      case 'cognitoHostedUI':
        console.log('Cognito Hosted UI sign in successful');
        break;
      case 'cognitoHostedUI_failure':
        console.log('Cognito Hosted UI sign in failed');
        break;
      case 'customOAuthState':
        console.log('custom state returned from CognitoHosted UI');
        break;
      case 'customState_failure':
        console.log('custom state failure');
        break;
      case 'parsingCallbackUrl':
        console.log('Cognito Hosted UI OAuth url parsing initiated');
        break;
      case 'signOut':
        setAuthStatus('unauthenticated');
        console.log('user signed out');
        break;
      case 'configured':
        console.log('the Auth module is configured');
        break;
    }
  };

  const Logout = () => {
    Auth.signOut();
  };

  useEffect(() => {
    Hub.listen('auth', listener);
    delayLoad();
  }, []);

  useEffect(() => {
    console.log('IS FADING: ', isFading);
    if (isFading) {
      fadeDivs();
    }
  }, [isFading]);

  const fadeDivs = () => {
    setAnimationOpacity(0);

    setTimeout(() => {
      setDivOpacity(1);
      setIsFading(false);
    }, 600);
  };

  const url = awsmobile.graphqlApiEndpoint;
  const region = awsmobile.region;

  if (loading || isFading) {
    console.log('animationOpacity', animationOpacity);
    return (
      <div
        className="animationHolder"
        id="animationHolder"
        style={{ opacity: animationOpacity }}
      >
        <Lottie
          style={{ width: '60%' }}
          animationData={initalLoadingAnimation}
          loop={true}
        />
      </div>
    );
  } else if (authStatus === 'authenticated') {
    const auth = {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: async () =>
        Auth.currentSession().then((value) => value.getIdToken().getJwtToken()),
      // jwtToken: Auth.user.signInUserSession.idToken.jwtToken,
    };

    const httpLink = new HttpLink({ uri: url });

    const link = ApolloLink.from([
      createAuthLink({ url, region, auth }),
      createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
    ]);

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });

    console.log('DIV OPACITY: ', divOpacity);

    return (
      <div className="apolloHolder" style={{ opacity: divOpacity }}>
        <ApolloProvider client={client}>{props.children}</ApolloProvider>
      </div>
    );
  } else {
    return (
      <div className="mainContainer" style={{ opacity: divOpacity }}>
        {/* <div
          className="containerContent containerImage"
          style={{
            backgroundImage: `url(${sidePanelImage})`,
          }}
        ></div> */}

        <div
          className="containerContent containerImage"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${wave})`,
          }}
        >
          <img src={sidePanelImage} style={{ width: '75%' }} />
        </div>

        {!loading && (
          <div className="containerContent containerBox">
            <div style={{ flex: 1 }}>
              {authStatus === 'unauthenticated' && (
                <SignIn
                  clearError={clearError}
                  error={error}
                  setAuthStatus={setAuthStatus}
                  setAuthSession={setAuthSession}
                  forgotPassword={() => {
                    setAuthStatus('forgotPassword');
                  }}
                />
              )}
              {authStatus === 'changePassword' && (
                <ChangePassword
                  clearError={clearError}
                  error={error}
                  setError={setError}
                  authSession={authSession}
                  setAuthStatus={setAuthStatus}
                  type={changePasswordType}
                />
              )}
              {authStatus === 'forgotPassword' && (
                <ForgotPassword
                  clearError={clearError}
                  error={error}
                  authSession={authSession}
                  backToLogin={() => {
                    setAuthStatus('unauthenticated');
                  }}
                  setAuthSession={setAuthSession}
                  readyToChangePassword={readyToChangePassword}
                />
              )}
              {authStatus === 'verificationCode' && (
                <VerificationCode
                  clearError={clearError}
                  error={error}
                  authSession={authSession}
                />
              )}
              {(authStatus === 'setupMFA' || authStatus === 'enterMFA') && (
                <MFA
                  clearError={clearError}
                  error={error}
                  user={authSession}
                  type={authStatus}
                />
              )}
              <div>
                <img
                  src={logo}
                  style={{
                    height: '65px',
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default Authentication;
