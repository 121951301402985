export const basicSearchCheck = (value, search) => {
  return value && value.toLowerCase().includes(search.toLowerCase());
};

export const checkBatchNumber = (value, search) => {
  return value.toLowerCase().inclues(search.toLowerCase());
};

export const checkStatus = (x) => {
  return false;
};

export const checkDisplayStatus = (x) => {
  return false;
};

export const checkRequestId = (x) => {
  return false;
};
