export const surveyBuilderOptionsMap = {
  instructions: 'Instructions',
  openEnded: 'Open Ended',
  multipleChoice: 'Multiple Choice',
  selectMany: 'Select Many',
  linearScale: 'Linear Scale',
  datetime: 'Date / Time',
};

export const surveyBuilderOptionsMapOld = {
  shortAnswer: 'Short Answer',
  paragraph: 'Paragraph',
  multipleChoice: 'Multiple Choice',
  checkbox: 'Checkbox',
  dropdown: 'Dropdown',
  linearScale: 'Linear Scale',
  date: 'Date',
  time: 'Time',
};

export const PAGE_LEVEL_ACCESS = [
  {
    label: 'Survey Builder',
    value: 'surveyBuilder',
  },
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Groups',
    value: 'groups',
  },
  {
    label: 'Precision Medicine',
    value: 'precisionMedicine',
  },
  {
    label: 'Analytics',
    value: 'analytics',
  },
];

const getSurveyBuilderOptions = () => {
  let arr = [];

  for (var key in surveyBuilderOptionsMap) {
    arr.push({
      name: surveyBuilderOptionsMap[key],
      type: key,
    });
  }

  return arr;
};

export const surveyBuilderOptions = getSurveyBuilderOptions();

export const surveyBuilderOptionsCanSplit = {
  shortAnswer: false,
  paragraph: false,
  multipleChoice: true,
  checkbox: false,
  dropdown: true,
  linearScale: true,
  date: false,
  time: false,
};

export const TEMP_CONFIG = {
  id: 'e859e9fe-ed26-43eb-8e02-f707176ee16d',
  name: 'Sacred Heart',
  description: 'Patient connection with the hospital',
  connectCode: null,
  addedBy: 'b3d7574c-8529-42b0-b972-5844a280bb11',
  createdAt: '2022-09-14T03:11:31.343Z',
  updatedAt: '2022-09-14T03:11:31.343Z',
  owner: null,
  addedByDisplayName: 'b3d7574c-8529-42b0-b972-5844a280bb11',
  actions: {
    edit: true,
    delete: true,
    manage: true,
  },
}; // HARDCODED CONFIG FOR EASY NAV to survey builder from home page (TECHNICAL DEBT)
export const LARGE_WINDOW_SIZE = 1802;
export const MEDIUM_WINDOW_SIZE = 1372;
export const SMALL_WINDOW_SIZE = 1055;
export const MOBILE_WINDOW_SIZE = 500;
export const TEMP_DASHBOARD = {
  id: 'f7570565-3c00-4af9-ad26-836c850509a5',
  name: 'Self Health Demo',
  link: 'f7570565-3c00-4af9-ad26-836c850509a5',
  description: 'Latest files',
  createdAt: '2022-08-01T04:53:05.142Z',
  updatedAt: '2022-08-01T04:53:05.142Z',
  owner: null,
  actions: {
    edit: false,
    delete: false,
    manage: true,
  },
};

export const DASHBOARD_IDS_LIST = [
  {
    routeName: 'overview',
    name: 'System Overview',
    id: 'f7570565-3c00-4af9-ad26-836c850509a5',
    isIframe: true,
  },
  {
    routeName: 'revenue-generation',
    name: 'Revenue Generation',
    id: 'cc408aa5-2c50-4486-a077-b9d20057178f',
    isIframe: true,
  },
  {
    routeName: 'operational-opportunities',
    name: 'Operational Opportunities',
    id: '3ab458af-3b05-44c5-acfc-5993f246dc68',
    isIframe: true,
  },
  {
    routeName: 'precision-medicine',
    name: 'Precision Medicine',
    id: 'b9689da8-2a21-4868-a940-7a0407583477',
    isIframe: false,
  },
  {
    routeName: 'overview',
    name: 'Survey Results',
    id: '6b6eed55-6f3e-4a4f-83ae-71a9294d8633',
    isIframe: true,
  },
];

export const TITLE_DISPLAY = (pathname, variables) => {
  switch (pathname) {
    case '/view-dashboard/overview':
      return 'Insights and Reporting';
    case '/view-dashboard/revenue-generation':
      return 'Insights and Reporting';
    case '/view-dashboard/operational-opportunities':
      return 'Insights and Reporting';
    case '/view-dashboard/precision-medicine':
      return 'Personalized Care Dashboard';
    case '/view-config/surveys':
      return 'Surveys';
    case '/users':
      return 'Users and Groups';
    case '/groups':
      return 'Group Management';
    case '/company-info':
      return 'Account Settings';
    case '/account':
      return 'My Account';
    default:
      return `Welcome, ${variables}!`;
  }
};
