import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Box, Button, Table, Modal } from 'Stories';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import PageForm from './Form.js';

import './Main.scss';

import { useLazyQuery, useMutation } from '@apollo/client';
import { BATCH_CREATE_KITS } from './GraphQL/mutations';
import { LIST_KITS } from './GraphQL/queries';

const labels = [
  {
    alignment: 'left',
    label: 'Update',
    key: 'updateCheckbox',
  },
  {
    alignment: 'left',
    label: 'Kit ID',
    key: 'title',
  },
  {
    alignment: 'left',
    label: 'Status',
    key: 'status',
  },
  {
    alignment: 'left',
    label: 'Patient Name',
    key: 'fullName',
  },
  {
    alignment: 'right',
    label: ' ',
    key: 'actions',
    size: 'small',
  },
];

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
};

export default function Surveys(props) {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [scanKitsModalOpen, setScanKitsModalOpen] = useState(false);
  const [bulkUpdateModalOpen, setBulkUpdateModalOpen] = useState(false);
  const [scannedKitIds, setScannedKitIds] = useState('');
  const [editInfo, setEditInfo] = useState({});
  const [kitModalInfo, setKitModalInfo] = useState({});
  const [data, setData] = useState([]);

  const [clinicId, setClinicId] = useState(null);

  const [page, setPage] = useState(0);
  const [bulkSelectedKits, setBulkSelectedKits] = useState([]);
  // const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  let navigate = useNavigate();

  useEffect(() => {
    let clinicName = window.location.pathname.split('/inventory/')[1];
    if (clinicName) {
      setClinicId(clinicName);
    } else {
      setClinicId('mp');
    }
  }, []);

  const toggleModal = (modalType, event) => {
    if (modalType === 'createKit') {
      setCreateModalOpen(!createModalOpen);
    } else if (modalType === 'scanKits') {
      setScanKitsModalOpen(!scanKitsModalOpen);
    } else if (modalType === 'bulkUpdate') {
      setBulkUpdateModalOpen(!bulkUpdateModalOpen);
    } else {
      setViewModalOpen(!viewModalOpen);
    }
  };

  const [listKits, { loading: listKitsLoading }] = useLazyQuery(LIST_KITS, {
    fetchPolicy: 'no-cache',
  });

  const [batchCreateKits, { loading: batchCreateKitsLoading }] =
    useMutation(BATCH_CREATE_KITS);

  function capitalizeWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getItems = async () => {
    const resp = await listKits({
      variables: {
        info: {
          page: page,
          limit: pageLimit,
        },
        clinic: clinicId,
      },
    });

    const { status, body } = resp.data.listKitsByClinic;

    try {
      if (status === 200) {
        let parsedData = JSON.parse(body);

        console.log('getItems -> parsedData', clinicId, parsedData);

        parsedData.forEach((kit) => {
          let parsedStatusHistory = JSON.parse(kit.statusHistory);

          if (parsedStatusHistory[0].status === null) {
            kit.statusHistory = null;
            kit.status = null;
          } else {
            kit.statusHistory = parsedStatusHistory
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((x) => {
                const statusDate = new Date(x.date);

                return {
                  ...x,
                  date: statusDate.toLocaleString('en-us', {
                    timeZone: 'America/Phoenix',
                  }),
                };
              });
          }
        });

        const formattedData = parsedData.map((kit) => {
          return {
            id: kit.id,
            title: kit.id,
            fullName: kit.patientId ? kit.firstName + ' ' + kit.lastName : null,
            status:
              kit.statusHistory && kit.statusHistory[0].status
                ? capitalizeWord(kit.statusHistory[0].status)
                : null,
            statusHistory: kit.statusHistory,
            patientId: kit.patientId,
            actions: { edit: true },
          };
        });

        setData(formattedData);
      }
    } catch (err) {
      console.log('Error with listKits. Check logs: ', err, resp);
    }
  };

  useEffect(() => {
    if (clinicId) {
      getItems();
    }
  }, [clinicId]);

  const onClickDelete = async (e) => {};

  const onClickEdit = (e) => {
    toggleModal(e);
  };

  const onClickViewKit = () => {
    toggleModal();
  };

  const handleScanChange = (e) => {
    console.log(e.split('\n'));
  };

  const onSubmitScanned = async () => {
    let allScannedKits = scannedKitIds.split('\n');
    console.log(allScannedKits);

    let resp = await batchCreateKits({
      variables: {
        kitIds: allScannedKits,
        clinic: clinicId,
      },
    });

    let parsedResponse = JSON.parse(resp.data.batchCreateKits.body);

    console.log('RESP: ', parsedResponse);

    setScannedKitIds('');

    toggleModal('scanKits');
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>Kits</div>
        <Button
          label="Scan Kits"
          onClick={(e) => toggleModal('scanKits', {})}
        />
        <div
          style={{
            display: 'flex',
          }}
        >
          {bulkSelectedKits.length > 0 && (
            <Button
              label="Bulk Update Statuses"
              onClick={(e) => toggleModal('bulkUpdate', {})}
              style={{ marginRight: '10px' }}
            />
          )}
          <Button
            label="Add Kit"
            onClick={(e) => toggleModal('createKit', {})}
          />
        </div>
      </div>

      {data.length > 0 && listKitsLoading ? (
        <p>loading...</p>
      ) : (
        <Table
          isKitTable
          labels={labels}
          data={data}
          bulkSelectedKits={bulkSelectedKits}
          setBulkSelectedKits={setBulkSelectedKits}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          toggleModal={toggleModal}
          setKitModalInfo={setKitModalInfo}
          capitalizeWord={capitalizeWord}
        />
      )}
      {/* TODO: Consolidate both modals into one reusable modal */}
      <Modal
        open={createModalOpen}
        toggle={() => toggleModal('createKit')}
        title={'Add Kit'}
      >
        <PageForm
          editInfo={editInfo}
          setModalOpen={setCreateModalOpen}
          setEditInfo={setEditInfo}
          capitalizeWord={capitalizeWord}
          createKitModal
        />
      </Modal>
      <Modal
        open={viewModalOpen}
        toggle={() => toggleModal('view')}
        title={'View Kit'}
      >
        <PageForm
          kitModalInfo={{ ...kitModalInfo, clinicId }}
          setKitModalInfo={setKitModalInfo}
          setModalOpen={setViewModalOpen}
          viewKitModal
        />
      </Modal>
      <Modal
        open={bulkUpdateModalOpen}
        toggle={() => toggleModal('bulkUpdate')}
        title={'Update Kit Statuses'}
        wide
      >
        <PageForm
          bulkSelectedKits={bulkSelectedKits}
          setModalOpen={setBulkSelectedKits}
          bulkUpdateModal
        />
      </Modal>

      <Modal
        open={scanKitsModalOpen}
        toggle={() => toggleModal('scanKits')}
        title={'Scan Kits'}
      >
        <TextInput
          label="Kit IDs"
          value={scannedKitIds}
          multiline={true}
          rows={4}
          onChange={(e) => setScannedKitIds(e)}
        />
        {!batchCreateKitsLoading ? (
          <Button style={buttonStyle} label="submit" onClick={onSubmitScanned}>
            Submit
          </Button>
        ) : (
          <CircularProgress />
        )}
      </Modal>
    </Box>
  );
}
