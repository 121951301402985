import React, { useState, useRef } from 'react';
import { Form, Select, TextInput, Button } from 'Stories';
import { CircularProgress } from '@mui/material';

import { useMutation } from '@apollo/client';
// import { PUT_KIT_STATUS } from 'Pages/Inventory/GraphQL/mutations';

export default function PageForm(props) {
  let {
    gene,
    genotype,
    description,
    clinical_notes,
    drugs,
    category,
    evidence_level,
  } = props.modalInfo;

  const onSubmit = (e) => {
    console.log('SUBMIT: ', e);
  };

  return (
    <div>
      <Form style={{ display: 'block' }} onSubmit={onSubmit}>
        <div>Gene: {gene}</div>
        <div>Genotype: {genotype}</div>
        <div>Category: {category}</div>
        <div>Evidence Level: {evidence_level}</div>

        <div>Drugbank Description: {clinical_notes}</div>

        <TextInput
          value={description}
          id="description"
          multiline
          rows={4}
          label={'Updated Description'}
        />
        <Button label="Update" id="submit" type="submit" />
      </Form>
    </div>
  );
}
