import React, { useState, useContext, useEffect } from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from 'Stories';
import { pageSection } from 'Services/MUIStylesOverrides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import './index.css';

export default function AccountInformation({ accountOwner }) {
  const [kits, setKits] = useState([]);

  return (
    <div className="Orders_SingleSectionHolder">
      <div className="Orders_SingleSectionTitle Orders_SingleSectionTitleSmall">
        <div>Account Information</div>
      </div>
      <div className="Orders_InformationBasicCard">
        <div className="Orders_InformationBasicTitle">Representative</div>
        <div>
          <div style={{ textTransform: 'capitalize' }}>{accountOwner}</div>
          <div>(324) 555 - 7382</div>
        </div>
      </div>

      <div className="Orders_InformationBasicCard">
        <div className="Orders_InformationBasicTitle">Primary Contact</div>
        <div>
          <div>Meredith Grey</div>
          <div>(324) 555 - 7382</div>
        </div>
      </div>
    </div>
  );
}
