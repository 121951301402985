import React from 'react';

import { Tooltip } from '@mui/material';
import { tooltip, arrow } from 'Services/MUIStylesOverrides';

import { LARGE_WINDOW_SIZE } from 'Services/GlobalConstants';
import { useWindowDimensions } from 'Services/CustomHooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import './Menu.css';

export default function ExpandButton({ open, setOpen, expandButtonPos }) {
  const [windowWidth] = useWindowDimensions();

  if (windowWidth >= LARGE_WINDOW_SIZE) {
    return null;
  } else {
    return (
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className="ExpandMenu_Button"
        style={{
          left: expandButtonPos,
        }}
      >
        <Tooltip
          title={`${open ? 'Collapse' : 'Expand'} menu`}
          placement="right"
          componentsProps={{
            tooltip: { sx: tooltip },
            arrow: { sx: arrow },
          }}
        >
          <FontAwesomeIcon
            icon={solid('angle-right')}
            style={{
              transform: `rotate(${open ? '-180deg' : '0deg'})`,
              transition: `all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
            }}
          />
        </Tooltip>
      </div>
    );
  }
}
