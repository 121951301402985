import { useState, useEffect } from 'react';
import { SearchBar, Button, TableCard } from 'Stories';
import { Paper } from '@mui/material';
import { pageSection } from 'Services/MUIStylesOverrides';

import './index.css';

const TABLE_HEADERS = [
  {
    name: 'Clinic',
    key: 'name',
  },
  {
    name: 'Kits',
    key: 'numberOfKits',
    align: 'right',
  },
];

export default function ClinicsTable({
  data,
  loading,
  noHeader,
  selectedData,
  showReviewButton,
  handleCreateNew,
  rowOnClick,
  didClickTab,
  tabSelected,
  ...props
}) {
  const [search, setSearch] = useState('');
  const [displayedData, setDisplayedData] = useState([]);

  useEffect(() => {
    console.log('displayed data: ', data);
    setDisplayedData(data);
  }, [data]);

  return (
    <Paper
      sx={{
        ...pageSection,
      }}
      className="clinics-section"
    >
      <div style={{ padding: 24 }}>
        <SearchBar
          placeholder={'Search Clinics'}
          value={search}
          onChange={(e) => setSearch(e)}
        />
        <div style={{ height: '25px' }}> </div>
        <TableCard
          data={displayedData}
          headers={TABLE_HEADERS}
          loading={loading}
          onRowClick={rowOnClick}
          selectedData={selectedData}
          selectedKey={'id'}
        />
        <div className="BatchTable_NewButtonHolder">
          <Button
            style={{
              borderRadius: 30,
              width: '255px',
            }}
            label="Create New Clinic"
            onClick={handleCreateNew}
          />
        </div>
      </div>
    </Paper>
  );
}
