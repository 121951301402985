import React from 'react';

import { useWindowDimensions } from 'Services/CustomHooks';
import './page.css';

import {
  LARGE_WINDOW_SIZE,
  MEDIUM_WINDOW_SIZE,
  SMALL_WINDOW_SIZE,
} from 'Services/GlobalConstants';

export default function Page({ pageTitle, children, actionButton }) {
  const [windowWidth, windowHeight] = useWindowDimensions();

  return (
    <>
      <div
        className="page-title"
        style={{
          flexDirection: windowWidth < SMALL_WINDOW_SIZE ? 'column' : 'row',
        }}
      >
        {pageTitle}
        {actionButton && actionButton}
      </div>
      {children}
    </>
  );
}
