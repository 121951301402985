import { gql } from '@apollo/client';

export const LIST_KITS = gql`
  query ($info: PaginationInput, $clinic: String) {
    listKitsByClinic(info: $info, clinic: $clinic) {
      status
      body
    }
  }
`;
