import { gql } from '@apollo/client';

export const CHECK_KIT = gql`
  query ($kitId: String) {
    checkKit(kitId: $kitId) {
      status
      body
    }
  }
`;
