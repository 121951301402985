import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import {
  Home,
  Account,
  CompanyInfo,
  Clinics,
  Inventory,
  GeneInteractions,
  ILabRequests,
  Batches,
  Orders,
} from 'Pages';

import {
  BrowserRouter as Router,
  Routes as DOMRoutes,
  Route,
} from 'react-router-dom';

export default function Routes() {
  return (
    <DOMRoutes>
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/inventory/*" element={<Inventory />} />
      <Route
        path="clinics/:clinicId?/:database_name?/:clinicName?"
        element={<Clinics />}
      />
      <Route path="/company-info" element={<CompanyInfo />} />
      <Route path="/account" element={<Account />} />
      <Route path="/gene-interactions" element={<GeneInteractions />} />
      <Route path="/ilab-requests" element={<ILabRequests />} />
      <Route path="/batches" element={<Batches />} />
      <Route path="/orders/:orderId?" element={<Orders />} />
      <Route path="/" element={<Home />} />
    </DOMRoutes>
  );
}
