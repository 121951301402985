import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'Stories';
import { useLazyQuery } from '@apollo/client';
import {
  COUNT_CLINIC_KITS,
  COUNT_CLINIC_PGX_TESTS,
  LIST_CLINIC_LOCATIONS,
} from '../GraphQL/queries';
import { Paper } from '@mui/material';

import './Style.css';
import { pageSection } from 'Services/MUIStylesOverrides';

import Lottie from 'lottie-react';
import loadingGif from 'Assets/dna_loader.json';
import { COUNT_CLINIC_PATIENTS } from 'Components/Clinics/ClinicsInfo/GraphQL/queries';

export default function PGxHighlightNumber({
  accountInfo,
  loading,
  database_name,
  togglePatientsModal,
}) {
  const [patientCount, setPatientCount] = useState(null);
  const [pgxCount, setPGxCount] = useState(null);
  const [locations, setLocations] = useState([]);

  let navigate = useNavigate();

  const [countClinicPatients, { loading: patientsLoading }] = useLazyQuery(
    COUNT_CLINIC_PATIENTS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [countClinicPGxTests, { loading: pgxLoading }] = useLazyQuery(
    COUNT_CLINIC_PGX_TESTS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [listClinicLocations, { loading: clinicLocationsLoading }] =
    useLazyQuery(LIST_CLINIC_LOCATIONS, {
      fetchPolicy: 'no-cache',
    });

  const getPatients = async () => {
    let resp = await countClinicPatients({
      variables: {
        clinicId: database_name,
      },
    });
    let x = JSON.parse(resp.data.countClinicPatients.body);
    setPatientCount(x.count);
  };

  const getTestsCount = async () => {
    let resp = await countClinicPGxTests({
      variables: {
        clinicId: database_name,
      },
    });
    let x = JSON.parse(resp.data.countClinicPGxTests.body);
    setPGxCount(x.count);
  };

  const getLocations = async () => {
    let resp = await listClinicLocations({
      variables: {
        clinicId: accountInfo.id,
      },
    });
    let x = JSON.parse(resp.data.listClinicLocations.body);
    console.log('LOC: ', x);
    setLocations(x);
  };

  useEffect(() => {
    if (database_name && accountInfo) {
      getPatients();
      getLocations();
      getTestsCount();
    }
  }, [accountInfo, database_name]);

  return (
    <Paper
      sx={{
        ...pageSection,
        height: 'fit-content',
        padding: '20px',
      }}
      className="HomePatientTable_Section"
    >
      <div className="Home_CardTitle">Account Information</div>
      <div style={{ padding: 20 }}>
        {loading ? (
          <div>
            <Lottie
              style={{
                width: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
                transform: 'rotate(20deg)',
                maxWidth: 200,
              }}
              animationData={loadingGif}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <ItemValueRow
              label="Order Trigger"
              value={accountInfo.minimumSwabOrderTrigger}
            />
            <ItemValueRow
              label="Order Amount"
              value={accountInfo.restockAmount}
            />
            <ItemValueRow
              label="Last Order"
              value={accountInfo.minimumSwabOrderTrigger}
            />
            <br />
            <Button
              style={{
                borderRadius: 30,
                minWidth: '150px',
                backgroundColor: 'var(--secondaryDark)',
              }}
              label={'Show Patients'}
              onClick={() => togglePatientsModal()}
            />
            <br />
            <ItemValueRow label="Patients" value={patientCount} />
            <ItemValueRow label="PGx Tests" value={pgxCount} />
            <br />
            <ItemValueRow label="Locations" value={''} />
            {locations.map((loc) => {
              return (
                <div style={{ marginTop: '15px' }}>
                  <div>{loc.streetAddressOne}</div>
                  <div>
                    {loc.city}, {loc.state} {loc.zipCode}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Paper>
  );
}

function ItemValueRow({ label, value }) {
  return (
    <div className="AI_Item_Holder">
      <div className="AI_Item_Label">{label}:</div>
      <div className="AI_Item_Value">{value}</div>
    </div>
  );
}
