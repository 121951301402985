import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import { TextInput, Form, Button, Table, Modal } from 'Stories';
import './Style.css';

export default function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = async (d) => {
    props.clearError();
    setLoading(true);

    props.setAuthSession({
      username: d.username.toLowerCase(),
    });
    await Auth.forgotPassword(d.username);
    props.readyToChangePassword();
    setLoading(false);
  };

  useEffect(() => {
    setError(props.error);
    if (props.error) {
      setLoading(false);
    }
  }, [props.error]);

  return (
    <div style={{ flex: 1 }}>
      <div className="pageTitle">Forgot Password</div>
      <div>
        <Form style={{ display: 'block' }} onSubmit={onSubmit}>
          <TextInput
            label="Email"
            id="username"
            type="username"
            form
            variant="outlined"
            required
          />
          <Button
            label={loading ? 'Submitting' : 'Submit'}
            id="submit"
            type="submit"
            disabled={loading}
            style={{ width: '100%' }}
          />
          <div className="forgotPasswordButton" onClick={props.backToLogin}>
            Back to Log in
          </div>
          {error && <div className="authErrorMessage">{error}</div>}
        </Form>
      </div>
    </div>
  );
}
