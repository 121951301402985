import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from '@mui/material';

import { dialog, tooltip, arrow } from 'Services/MUIStylesOverrides';

import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';

import { default as ClinicTable } from './ClinicTable';

const TABLE_HEADERS = [
  {
    name: 'ID',
    key: 'clinicPatientId',
    sortable: true,
  },
  {
    name: 'Name',
    key: 'displayName',
    sortable: true,
  },
  {
    name: 'DOB',
    key: 'DOB',
    size: 'small',
    sortable: true,
  },
  {
    name: 'Last Updated',
    key: 'updDate',
    sortable: true,
  },
];

const LIMIT = 5;

export default function Main(props) {
  const navigate = useNavigate();
  const { open } = props;

  const openClinics = (clinic) => {
    navigate(`/clinics/${clinic.id}/${clinic.database_name}/${clinic.name}`);
  };

  return (
    <div>
      <Dialog open={open} minWidth={'lg'} maxWidth={'lg'} sx={dialog}>
        <DialogTitle sx={{ padding: 0 }}>
          <div className="Home_CardTitle" style={{ fontSize: '1.5em' }}>
            Select a Clinic
          </div>
          <Tooltip
            title="Back to Home Page"
            arrow
            placement="left"
            componentsProps={{
              tooltip: { sx: tooltip },
              arrow: { sx: arrow },
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                navigate('/');
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <ChevronLeft />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <ClinicTable rowOnClick={openClinics} headers={TABLE_HEADERS} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
