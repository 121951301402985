import { useState, useEffect } from 'react';
import { SearchBar, TableCard } from 'Stories';

import moment from 'moment';

import './Style.css';
import { useLazyQuery } from '@apollo/client';

import {
  COUNT_CLINICS,
  LIST_CLINICS,
  LIST_PATIENTS_BY_CLINIC,
} from '../GraphQL/queries';

const TABLE_HEADERS = [
  {
    name: 'Patient',
    key: 'displayName',
    sortable: true,
  },
  {
    name: 'Kit Status',
    key: 'kitStatus',
    size: 'small',
    sortable: true,
  },
];

const LIMIT = 25;

export default function Main({ rowOnClick, loading, clinicId }) {
  const [search, setSearch] = useState('');

  const [displayedData, setDisplayedData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState([]);

  const [listConditions, setListConditions] = useState({
    sortDirection: 'asc',
    sortProperty: 'name',
    page: 0,
  });

  const [page, setPage] = useState(0);

  // const [countClinics, { loading: patientCountLoading }] = useLazyQuery(
  //   COUNT_CLINICS,
  //   {
  //     fetchPolicy: 'no-cache',
  //   }
  // );

  // const [
  //   listClinics,
  //   { loading: isClinicsLoading, data: taskData, error: taskError },
  // ] = useLazyQuery(LIST_CLINICS, {
  //   fetchPolicy: 'no-cache',
  // });

  const [
    listPatientsByClinic,
    { loading: isClinicPatientsLoading, data: taskData, error: taskError },
  ] = useLazyQuery(LIST_PATIENTS_BY_CLINIC, {
    fetchPolicy: 'no-cache',
  });

  const loadData = async () => {
    let resp = await listPatientsByClinic({
      variables: {
        info: {
          page: page,
          limit: 10,
        },
        clinicId,
      },
    });

    let data = JSON.parse(resp.data.listPatientsByClinic.body);

    console.log(data);
    if (data && data.length && data.length > 0) {
      for (var i = 0; i < data.length; ++i) {
        const displayName = `${data[i].firstName} ${data[i].lastName}`;
        data[i].displayName = displayName;
      }

      setDisplayedData(data);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSearch = () => {
    setDisplayedData([]);
    setListConditions({
      ...listConditions,
      page: 0,
      searchTerm: search,
    });
  };

  const triggerSortSearch = (property, order) => {
    setDisplayedData([]);
    setListConditions({
      ...listConditions,
      page: 0,
      sortDirection: order,
      sortProperty: property,
    });
  };

  const loadNextPage = () => {
    setListConditions({
      ...listConditions,
      page: listConditions.page + 1,
    });
  };

  useEffect(() => {
    loadData();
  }, [listConditions]);

  return (
    <div style={{ padding: 24 }}>
      <SearchBar
        placeholder={'Search Patient by Name'}
        value={search}
        onEnterListener={handleSearch}
        onChange={(e) => setSearch(e)}
      />
      <div style={{ height: '25px' }}> </div>
      <TableCard
        data={displayedData}
        headers={TABLE_HEADERS}
        loading={loading && displayedData.length === 0}
        onRowClick={(e) => {
          rowOnClick(e);
        }}
        infiniteScroll={true}
        loadMoreHandler={loadNextPage}
        totalRowCount={totalRowCount}
        searchActive={search}
        sortable
        sortDefault="displayName"
        sortTrigger={(p, o) => triggerSortSearch(p, o)}
      />
    </div>
  );
}
