import React from 'react';
import { Auth } from 'aws-amplify';
import { Button } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useNavigate } from 'react-router-dom';

import './Menu.css';

export default function FooterButtons({ icon, title }) {
  let navigate = useNavigate();
  return (
    <div className="MenuFooter_Buttons">
      <Button
        variant="link"
        sx={{ textTransform: 'capitalize' }}
        onClick={() => {
          window.open(
            'https://magnoliapoint.atlassian.net/servicedesk/customer/portal/3'
          );
        }}
      >
        <FontAwesomeIcon
          icon={solid('circle-info')}
          style={{ paddingRight: 1 }}
        />
        Need Help?
      </Button>
      <Button
        variant="link"
        sx={{ textTransform: 'capitalize' }}
        onClick={() => {
          Auth.signOut();
          navigate('/');
        }}
      >
        <FontAwesomeIcon
          icon={solid('sign-out-alt')}
          style={{ paddingRight: 1 }}
        />
        Logout
      </Button>
    </div>
  );
}
