import { gql } from '@apollo/client';

export const LIST_BATCHES = gql`
  query ($info: PaginationInput) {
    listBatches(info: $info) {
      status
      body
    }
  }
`;

export const GET_KITS_IN_BATCH = gql`
  query ($batchId: String) {
    getKitsInBatch(batchId: $batchId) {
      status
      body
    }
  }
`;
