import { gql } from '@apollo/client';

export const LIST_CLINICS = gql`
  query ($info: PaginationInput) {
    listClinics(info: $info) {
      status
      body
    }
  }
`;

export const COUNT_CLINICS = gql`
  query {
    countClinics {
      status
      body
    }
  }
`;

export const LIST_ACCOUNT_OWNERS = gql`
  query ($info: PaginationInput) {
    listAccountOwners(info: $info) {
      status
      body
    }
  }
`;

export const COUNT_CLINIC_PATIENTS = gql`
  query ($clinicId: String) {
    countClinicPatients(clinicId: $clinicId) {
      status
      body
    }
  }
`;

export const LIST_PATIENTS_BY_CLINIC = gql`
  query ($info: PaginationInput, $clinicId: String) {
    listPatientsByClinic(info: $info, clinicId: $clinicId) {
      status
      body
    }
  }
`;

export const COUNT_CLINIC_KITS = gql`
  query ($clinicId: String, $clinicRef: String) {
    countClinicKits(clinicId: $clinicId, clinicRef: $clinicRef) {
      status
      body
    }
  }
`;

export const LIST_ORDERS_BY_CLINIC = gql`
  query ($info: PaginationInput, $clinicId: String) {
    listOrdersByClinic(info: $info, clinicId: $clinicId) {
      status
      body
    }
  }
`;

export const LIST_CLINIC_LOCATIONS = gql`
  query ($clinicId: String) {
    listClinicLocations(clinicId: $clinicId) {
      status
      body
    }
  }
`;

export const GET_CLINIC_ACCOUNT_INFO = gql`
  query ($clinicId: String) {
    getClinicAccountInfo(clinicId: $clinicId) {
      status
      body
    }
  }
`;

export const GET_KIT_STATUS_ALERTS = gql`
  query ($clinicRef: String) {
    getKitStatusAlerts(clinicRef: $clinicRef) {
      status
      body
    }
  }
`;

export const COUNT_CLINIC_PGX_TESTS = gql`
  query ($clinicId: String) {
    countClinicPGxTests(clinicId: $clinicId) {
      status
      body
    }
  }
`;

export const LIST_OUTSTANDING_ORDERS_BY_CLINIC = gql`
  query ($clinicId: String, $info: PaginationInput) {
    listOutstandingOrdersByClinic(clinicId: $clinicId, info: $info) {
      status
      body
    }
  }
`;

export const GET_ORDER = gql`
  query ($orderId: String) {
    getOrder(orderId: $clinicId) {
      status
      body
    }
  }
`;
