import { useState, useEffect } from 'react';
import { SearchBar, Button, TableCard } from 'Stories';
import { Paper } from '@mui/material';
import { pageSection } from 'Services/MUIStylesOverrides';
import { basicSearchCheck } from './utility.js';

import './index.css';

const TABLE_HEADERS = [
  {
    name: 'Batch #',
    key: 'id',
  },
  {
    name: 'Status',
    key: 'displayStatus',
  },
  {
    name: 'iLab Request ID',
    key: 'ilabRequestId',
    align: 'right',
  },
];

export default function BatchesTable({
  data,
  loading,
  selectedData,
  handleCreateNew,
  rowOnClick,
  tableHeaders,
}) {
  const [search, setSearch] = useState('');
  const [displayedData, setDisplayedData] = useState([]);
  const [rawData, setRawData] = useState([]);

  const handleSearch = () => {
    let newData = [];

    for (var i = 0; i < rawData.length; ++i) {
      let passedBatchNumber = basicSearchCheck(rawData[i].id, search);
      let passedDisplayStatus = basicSearchCheck(
        rawData[i].displayStatus,
        search
      );
      let passedStatus = basicSearchCheck(rawData[i].status, search);
      let passedRequestId = basicSearchCheck(rawData[i].ilabRequestId, search);

      if (
        passedBatchNumber ||
        passedDisplayStatus ||
        passedStatus ||
        passedRequestId
      ) {
        newData.push(rawData[i]);
      }
    }

    setDisplayedData(newData);
  };

  useEffect(() => {
    if (search && search.length) {
      handleSearch();
    } else {
      setDisplayedData(rawData);
    }
  }, [search]);

  useEffect(() => {
    setDisplayedData(data);
    setRawData(data);
  }, [data]);

  return (
    <Paper
      sx={{
        ...pageSection,
      }}
      className="clinics-section"
    >
      <div style={{ padding: 24 }}>
        <SearchBar
          placeholder={'Search Batches'}
          value={search}
          onChange={(e) => setSearch(e)}
        />
        <div style={{ height: '25px' }}> </div>
        <TableCard
          data={displayedData}
          headers={TABLE_HEADERS}
          loading={loading}
          onRowClick={rowOnClick}
          selectedData={selectedData}
          selectedKey={'id'}
        />
        <div className="BatchTable_NewButtonHolder">
          <Button
            style={{
              borderRadius: 30,
              width: '255px',
            }}
            label="Create New Batch"
            onClick={handleCreateNew}
          />
        </div>
      </div>
    </Paper>
  );
}
