import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { pageSection } from 'Services/MUIStylesOverrides';

import './index.css';

import { useLazyQuery } from '@apollo/client';

import { GET_KITS_IN_INVENTORY } from 'Pages/Orders/GraphQL/queries.js';

export default function KitsSection(props) {
  const [totalKits, setTotalKits] = useState(null);

  const navigate = useNavigate();

  const [getKitsInInventory, { loading: isKitsInInventoryLoading }] =
    useLazyQuery(GET_KITS_IN_INVENTORY, {
      fetchPolicy: 'no-cache',
    });

  const initKitsInInventory = async () => {
    let resp = await getKitsInInventory();
    let c = JSON.parse(resp.data.getKitsInInventory.body).count;
    setTotalKits(c);
  };

  useEffect(() => {
    initKitsInInventory();
  }, []);

  return (
    <Paper
      sx={{
        ...pageSection,
      }}
      className={'kits-container'}
      onClick={() => navigate('/inventory')}
    >
      <div className="kits-title">
        <h2>Kits</h2>
      </div>
      <div className="kits-total-container">
        <h4 className="kits-total-title">Total Amount of Kits:</h4>
        <h3 className="kits-total-text">{totalKits}</h3>
      </div>
    </Paper>
  );
}
