import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Chip } from '@mui/material';
import { Lock } from '@mui/icons-material';
export default function BasicListItem(props) {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const { name, icon, isLocked, postIcon } = props.item;
  //const isLocked = true;
  return (
    <ListItem
      key={name}
      sx={{ display: 'block', ...props.sx }}
      onClick={(e) => props.handleClick(props.item)}
    >
      <ListItemButton
        selected={props.selected}
        disabled={isLocked}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          display: 'block',
          textAlign: props.alignItems || 'left',
          borderTopRightRadius: 30,
          borderBottomRightRadius: 30,
          '&:hover': {
            background: 'white',
            color: 'var(--primaryDark) !important',
            '& .MuiListItemIcon-root': {
              color: 'var(--primaryDark) !important',
            },
          },
          paddingLeft: 5,
          '&&.Mui-selected': {
            background: 'white',
            color: 'var(--primaryDark)',
          },
          '&&.Mui-disabled': {
            background: '#dcdcdc',
            opacity: 0.9,
          },
        }}
      >
        <ListItemIcon
          sx={{
            fontSize: 28,
            width: 20,
            display: 'inline-block',
            minWidth: 0,
            mr: 3,
            justifyContent: 'center',
            color: props.selected
              ? 'var(--primaryDark)'
              : props.color
              ? props.color
              : 'white',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={props.item.name}
          sx={{
            display: 'block',
            fontSize: '12px',
            fontWeight: 'bold',
            display: 'inline-block',
            position: 'relative',
            top: -4,
          }}
        />
        {postIcon && (
          <div style={{ position: 'absolute', right: 15, top: 17 }}>
            {postIcon}
          </div>
        )}
      </ListItemButton>
      {isLocked && open && (
        <Chip
          label="Pro Feature"
          avatar={
            <Lock style={{ height: 15, color: 'white', marginBottom: 3 }} />
          }
          sx={{
            backgroundColor: '#3dc1eb',
            color: 'white',
            height: 20,
            position: 'absolute',
            right: 50,
            top: 45,
            m: 1,
          }}
        />
      )}
    </ListItem>
  );
}

BasicListItem.defaultProps = {
  handleClick: () => {},
};
