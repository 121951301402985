import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Table, Modal } from 'Stories';
import { useNavigate, useLocation } from 'react-router-dom';

import { store } from 'Contexts/GlobalState';

import { LIST_REQUESTS, GET_REQUEST_DETAILS } from './GraphQL/queries.js';

import { useLazyQuery, useMutation } from '@apollo/client';

const labels = [
  {
    alignment: 'left',
    label: 'Requested By',
    key: 'name',
  },
  {
    alignment: 'left',
    label: 'Date',
    key: 'dateRequested',
  },
  {
    alignment: 'left',
    label: 'Status',
    key: 'status',
  },
  {
    alignment: 'left',
    label: 'Cost',
    key: 'actualCost',
    size: 'small',
  },
  {
    alignment: 'right',
    label: 'Actions',
    key: 'actions',
    size: 'small',
  },
];

export default function GroupManagement(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [editInfo, setEditInfo] = useState({});
  const [cookie, setCookie] = useState(
    '_session_id=aae4a60012e300a31dbd5209e2b9dc00;'
  );

  console.log(cookie);

  const [groups, setGroups] = useState([]);

  const toggleModal = (e) => {
    setModalInfo(e);
    setModalOpen(!modalOpen);
  };

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;
  let navigate = useNavigate();

  const [
    listRequests,
    { loading: taskLoading, data: taskData, error: taskError },
  ] = useLazyQuery(LIST_REQUESTS, {});

  const [getRequestDetails, { loading: requestLoading }] = useLazyQuery(
    GET_REQUEST_DETAILS,
    {}
  );

  const getRequests = async () => {
    let resp = await listRequests();

    let allData = JSON.parse(resp.data.listRequests.body);
    allData = JSON.parse(allData.body);

    let data = allData.info;
    let token = allData.token;

    for (var i = 0; i < data.length; ++i) {
      data[i].actions = {
        edit: true,
        delete: false,
      };
    }

    setCookie(token);
    setGroups(data);
  };

  const onClickEdit = async (e) => {
    let resp = await getRequestDetails({
      variables: {
        id: e.id,
      },
    });

    let allData = JSON.parse(resp.data.getRequestDetails.body);
    allData = JSON.parse(allData.body);

    toggleModal(allData.info);

    // let data = allData.info;

    // let details = fixHtml(data[0]);
    // let serviceInfo = fixHtml(data[1]);

    // console.log('serviceInfo', serviceInfo);
    // console.log('details', details);

    // console.log('FIXED!!!!!');

    // toggleModal({
    //   details: details,
    //   serviceInfo: serviceInfo,
    // });
  };

  const fixHtml = (x) => {
    let duped = x.replace(/\\n/g, '');
    duped = duped.replace(/\\\//g, '/');

    let indexOfFirst = duped.indexOf('<');

    duped = duped.substring(indexOfFirst, duped.length);
    return duped;
  };

  const onClickRow = async (e) => {
    // console.log('Row click: ', e);
    // navigate('/inventory/' + e.database_name);
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>iLab Requests</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          {
            <Button
              label="Add new clinic"
              onClick={(e) => {
                // setEditInfo({ type: 'new' });
                // toggleModal({});
                getRequests();
              }}
            />
          }
        </div>
      </div>
      <Table
        labels={labels}
        data={groups}
        onClickEdit={onClickEdit}
        confirmDelete={true}
      />
      <Modal
        open={modalOpen}
        toggle={toggleModal}
        title={`View Request Details`}
      >
        <div>
          {modalInfo &&
            modalInfo.attachments &&
            modalInfo.attachments.map((item, index) => {
              return (
                <div>
                  <div>{item.downloadLinkName}</div>
                  <div>{item.uploadedDate}</div>
                  <div>{item.uploaderName}</div>
                </div>
              );
            })}

          {modalInfo &&
            modalInfo.comments &&
            modalInfo.comments.map((item, index) => {
              return (
                <div>
                  <div>{item.name}</div>
                  <div>{item.date}</div>
                  <div>{item.comment.toString()}</div>
                </div>
              );
            })}
        </div>
      </Modal>
    </Box>
  );
}
