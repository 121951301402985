import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Modal } from 'Stories';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function BasicTable(props) {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  const toggleModal = () => {
    if (confirmDeleteModal) setModalInfo(null);
    setConfirmDeleteModal(!confirmDeleteModal);
  };

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        style={{ tableLayout: 'fixed' }}
      >
        {props.labels && props.labels.length > 0 && !props.customHeader ? (
          <TableHead>
            <TableRow>
              {props.labels.map((item, index) => {
                return (
                  <TableCell
                    align={item.alignment ? item.alignment : 'left'}
                    size={item.size ? item.size : 'medium'}
                    key={'TableLabel_' + index}
                    variant={'head'}
                  >
                    {item.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        {props.customHeader}
        <TableBody>
          {props.data &&
            props.data.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: props.clickableRows ? 'pointer' : 'default',
                  '&:hover': {
                    backgroundColor: props.clickableRows ? '#eaeaea' : 'none',
                  },
                }}
                onClick={(e) => {
                  if (!props.onRowClick) return;
                  props.onRowClick(row);
                }}
              >
                {props.labels.map((label) => {
                  let rowInfo = null;

                  if (label.key === 'actions') {
                    rowInfo = (
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        {row.actions.edit && (
                          <div
                            style={{ paddingLeft: '10px', cursor: 'pointer' }}
                            onClick={() => {
                              if (props.onClickEdit) props.onClickEdit(row);
                            }}
                          >
                            <FontAwesomeIcon
                              size="lg"
                              icon={solid('pen-to-square')}
                            />
                          </div>
                        )}

                        {row.actions.diagram && (
                          <div
                            style={{ paddingLeft: '10px', cursor: 'pointer' }}
                            onClick={() => {
                              if (props.onClickDiagram)
                                props.onClickDiagram(row);
                            }}
                          >
                            <FontAwesomeIcon
                              size="lg"
                              icon={solid('diagram-project')}
                            />
                          </div>
                        )}

                        {row.actions.delete && (
                          <div
                            style={{ paddingLeft: '10px', cursor: 'pointer' }}
                            onClick={() => {
                              if (props.confirmDelete) {
                                setModalInfo(row);
                                setConfirmDeleteModal(true);
                              } else if (props.onClickDelete) {
                                props.onClickDelete(row);
                              }
                            }}
                          >
                            <FontAwesomeIcon size="lg" icon={solid('trash')} />
                          </div>
                        )}

                        {row.actions.manage && (
                          <div
                            style={{ paddingLeft: '10px', cursor: 'pointer' }}
                            onClick={() => {
                              if (props.onClickManage) props.onClickManage(row);
                            }}
                          >
                            <FontAwesomeIcon size="lg" icon={solid('wrench')} />
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    rowInfo = row[label.key];
                  }

                  return (
                    <TableCell
                      key={uuidv4()}
                      align={label.alignment}
                      size={label.size ? label.size : 'medium'}
                      style={{
                        ...props.tableCellGeneralStyles,
                        ...(props.tableCellColumnStyles &&
                          props.tableCellColumnStyles[label.key]),
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        width: 'auto',
                        overflow: 'hidden',
                      }}
                    >
                      {rowInfo}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Modal
        open={confirmDeleteModal}
        toggle={toggleModal}
        title={'Confirm Delete'}
        confirmation={true}
        toggleModal={toggleModal}
        modalInfo={modalInfo}
        onClickDelete={() => {
          props.onClickDelete(modalInfo);
          toggleModal();
        }}
      >
        <div>Are you sure you want to delete?</div>
      </Modal>
    </TableContainer>
  );
}
