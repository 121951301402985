import React, { useEffect, useState } from 'react';
import { Page, Button } from 'Stories';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { ClinicsInfo, ClinicsTable } from 'Components';
import { SelectClinicModal } from './SelectClinicModal/index.js';
import { ClinicPatientsModal } from './ClinicPatientsModal/index.js';
import { MEDIUM_WINDOW_SIZE } from 'Services/GlobalConstants.js';
import { useWindowDimensions } from 'Services/CustomHooks.js';
import { ClinicCard } from './ClinicCard/index.js';
import { Orders } from './Orders/index.js';
import { Kits } from './Kits/index.js';
import { AccountInformation } from './AccountInformation/index.js';
import { GET_CLINIC_ACCOUNT_INFO } from './GraphQL/queries.js';
import { useLazyQuery } from '@apollo/client';

export default function GroupManagement(props) {
  const params = useParams();
  const { clinicId, database_name, clinicName } = params;

  const [accountInfo, setAccountInfo] = useState({});
  const [openPatientsModal, setOpenPatientsModal] = useState(false);

  const navigate = useNavigate();

  const [windowWidth] = useWindowDimensions();

  const [getClinicAccountInfo, { loading: accountInfoLoading }] = useLazyQuery(
    GET_CLINIC_ACCOUNT_INFO,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getInfo = async () => {
    let resp = await getClinicAccountInfo({
      variables: {
        clinicId: clinicId,
      },
    });

    let x = JSON.parse(resp.data.getClinicAccountInfo.body);
    setAccountInfo(x);
  };

  useEffect(() => {
    if (clinicId) {
      getInfo();
    }
  }, [clinicId]);

  const getButton = () => {
    return (
      <Button
        style={{
          borderRadius: 30,
          width: '255px',
          backgroundColor: 'var(--secondaryDark)',
        }}
        label={'Select Clinic'}
        onClick={(e) => navigate('/clinics')}
      />
    );
  };

  const togglePatientsModal = () => {
    setOpenPatientsModal(!openPatientsModal);
  };

  return (
    <Page pageTitle="Clinics" actionButton={getButton()}>
      <SelectClinicModal open={!clinicId} />
      <ClinicPatientsModal
        open={openPatientsModal}
        toggle={togglePatientsModal}
        clinicId={clinicId}
      />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns:
            windowWidth < MEDIUM_WINDOW_SIZE ? '1fr' : '1fr 1fr 1fr',
        }}
      >
        <div>
          <ClinicCard
            name={clinicName}
            id={clinicId}
            accountInfo={accountInfo}
          />

          <AccountInformation
            accountInfo={accountInfo}
            loading={accountInfoLoading}
            database_name={database_name}
            togglePatientsModal={togglePatientsModal}
          />
        </div>

        <Orders id={clinicId} database_name={database_name} />
        <Kits id={clinicId} database_name={database_name} />

        {windowWidth < MEDIUM_WINDOW_SIZE && (
          <div style={{ height: '25px' }}> </div>
        )}
      </div>
    </Page>
  );
}
