import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import { TextInput, Form, Button, Table, Modal } from 'Stories';
import './Style.css';

export default function SignIn(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const onSubmit = async (d) => {
    props.clearError();
    setLoading(true);
    let resp = await Auth.signIn(username, password);
    if (resp.challengeName === 'NEW_PASSWORD_REQUIRED') {
      props.setAuthStatus('changePassword');
      props.setAuthSession(resp);
    } else if (resp.challengeName === 'MFA_SETUP') {
      props.setAuthStatus('setupMFA');
      props.setAuthSession(resp);
    } else if (resp.challengeName === 'SOFTWARE_TOKEN_MFA') {
      props.setAuthStatus('enterMFA');
      props.setAuthSession(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    setError(props.error);
    if (props.error) {
      setLoading(false);
    }
  }, [props.error]);

  return (
    <div style={{ flex: 1 }}>
      <div className="pageTitle">Log In</div>
      <div>
        <Form style={{ display: 'block' }} onSubmit={onSubmit}>
          <TextInput
            label="Username"
            id="username"
            type="username"
            form
            variant="outlined"
            onChange={(e) => setUsername(e)}
          />
          <TextInput
            label="Password"
            id="password"
            form
            variant="outlined"
            type="password"
            onChange={(e) => setPassword(e)}
          />
          <div
            className="forgotPasswordButton"
            onClick={() => props.forgotPassword()}
          >
            Forgot password?
          </div>
          <Button
            label={loading ? 'Logging you in' : 'Log in'}
            id="submit"
            type="submit"
            disabled={loading}
            style={{ width: '100%' }}
          />
          {error && <div className="authErrorMessage">{error}</div>}
        </Form>
      </div>
    </div>
  );
}
