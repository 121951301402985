import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { pageSection } from 'Services/MUIStylesOverrides';

import './index.css';
import { useLazyQuery } from '@apollo/client';

import { LIST_ORDERS } from 'Pages/Orders/GraphQL/queries.js';

export default function OrdersSection({ orders }) {
  const [orderCount, setOrders] = useState(null);
  const navigate = useNavigate();

  const [
    listOrders,
    { loading: isOrdersLoading, data: taskData, error: taskError },
  ] = useLazyQuery(LIST_ORDERS, {
    fetchPolicy: 'no-cache',
  });

  const getLazyOrderCount = async () => {
    let resp = await listOrders({
      variables: {
        info: {
          page: 0,
          limit: 100,
        },
        type: 'outstanding',
      },
    });

    let data = JSON.parse(resp.data.listOrders.body);
    setOrders(data.length);
  };

  useEffect(() => {
    getLazyOrderCount();
  }, []);

  return (
    <Paper
      sx={{
        ...pageSection,
      }}
      className={'orders-container'}
      onClick={() => navigate('/orders')}
    >
      <h2 className={'orders-title'}>Orders Outstanding</h2>
      <div className={'orders-results-count'}>{orderCount}</div>
    </Paper>
  );
}
