import React, { useState, useEffect } from 'react';

import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { dialog } from 'Services/MUIStylesOverrides.js';

import { Close } from '@mui/icons-material';

import './Style.css';
import { SearchBar, TableCard } from 'Stories';

const TABLE_HEADERS = [
  {
    name: 'Kit ID',
    key: 'kitId',
    sortable: true,
  },
  {
    name: 'First Name',
    key: 'firstName',
    size: 'small',
    sortable: true,
  },
  {
    name: 'Last Name',
    key: 'lastName',
    size: 'small',
    sortable: true,
  },
  {
    name: 'Days in Status',
    key: 'daysInStatus',
    size: 'small',
    sortable: true,
  },
];

export default function Main({ open, toggle, data }) {
  const [displayedData, setDisplayedData] = useState([]);
  const [search, setSearch] = useState('');
  const [listConditions, setListConditions] = useState({
    sortDirection: 'asc',
    sortProperty: 'name',
    page: 0,
  });

  let { name, alarms } = data;

  useEffect(() => {
    if (alarms) {
      setDisplayedData(alarms);
    } else {
      setDisplayedData([]);
    }
  }, [alarms]);

  const handleSearch = () => {
    if (search && search.length > 0) {
      let arr = [];

      for (var i = 0; i < alarms.length; ++i) {
        let x = alarms[i];
        if (
          x.firstName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          x.lastName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          x.kitId.toLowerCase().indexOf(search.toLowerCase()) > -1
        ) {
          arr.push(x);
        }
      }

      setDisplayedData(arr);
    } else {
      setDisplayedData(alarms);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  const triggerSortSearch = (property, order) => {
    setDisplayedData([]);
    setListConditions({
      ...listConditions,
      page: 0,
      sortDirection: order,
      sortProperty: property,
    });
  };

  return (
    <div>
      <Dialog open={open} minWidth={'lg'} maxWidth={'lg'} sx={dialog}>
        <DialogTitle sx={{ padding: 0 }}>
          <div className="KitAlarmCardTitle" style={{ fontSize: '1.5em' }}>
            {name}
            <IconButton
              style={{ float: 'right', color: 'white' }}
              onClick={toggle}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: 24 }}>
            <SearchBar
              placeholder={'Search Patient by Name'}
              value={search}
              onEnterListener={handleSearch}
              onChange={(e) => setSearch(e)}
            />
            <div style={{ height: '25px' }}> </div>
            <TableCard
              data={displayedData && displayedData.length ? displayedData : []}
              headers={TABLE_HEADERS}
              loading={!displayedData}
              infiniteScroll={false}
              onRowClick={() => {}}
              searchActive={search}
              sortable
              sortDefault="daysInStatus"
              sortTrigger={(p, o) => triggerSortSearch(p, o)}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
