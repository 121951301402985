import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const independentMenus = [
  {
    name: 'Home',
    icon: <FontAwesomeIcon icon={solid('house')} />,
    path: '/',
    pageAccess: 'All',
    isAvailable: true,
  },
  {
    name: 'Clinics',
    icon: <FontAwesomeIcon icon={solid('hospital')} />,
    path: '/clinics',
    pageAccess: 'All',
    isAvailable: true,
  },
  // {
  //   name: 'Kits',
  //   icon: <FontAwesomeIcon icon={solid('boxes-stacked')} />,
  //   path: '/inventory',
  //   pageAccess: 'All',
  // isAvailable: true,
  // },
  {
    name: 'Orders',
    icon: <FontAwesomeIcon icon={solid('clipboard')} />,
    path: '/orders',
    pageAccess: 'All',
    isAvailable: true,
  },
  // {
  //   name: 'Gene Interactions',
  //   icon: <FontAwesomeIcon icon={solid('dna')} />,
  //   path: '/gene-interactions',
  //   pageAccess: 'All',
  // },
  {
    name: 'Batches',
    icon: <FontAwesomeIcon icon={solid('vials')} />,
    path: '/batches',
    pageAccess: 'All',
    isAvailable: true,
  },
  // {
  //   name: 'iLab Requests',
  //   icon: <img src={ilabLogo} style={{ height: '35px', width: '35px' }} />,
  //   path: '/ilab-requests',
  //   pageAccess: 'All',
  //   flex: true,
  // },
];

export const accountSettingsMenu = [
  // {
  //   name: 'Users',
  //   icon: <FontAwesomeIcon icon={solid('users')} />,
  //   path: '/users',
  //   pageAccess: 'users',
  // },
  {
    name: 'My Account',
    icon: <FontAwesomeIcon icon={solid('circle-user')} />,
    path: '/account',
    pageAccess: 'All',
  },
];

export const AllMenuItems = [
  {
    sectionTitle: null,
    items: independentMenus,
  },
];
