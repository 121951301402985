import React, { useState, useEffect } from 'react';
import { Form, Select, Table, Button } from 'Stories';
import { CircularProgress } from '@mui/material';

import { useMutation } from '@apollo/client';
// import { PUT_KIT_STATUS } from 'Pages/Inventory/GraphQL/mutations';

const kitStatusOptions = [
  { value: 'received', label: 'Received' },
  { value: 'analyzing', label: 'Analyzing' },
  { value: 'completed', label: 'Completed' },
];

export default function BulkUpdateKitsFormContent(props) {
  const { bulkSelectedKits } = props;

  const [kitStatus, setKitStatus] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);

  useEffect(() => {
    console.log(kitStatus);
  }, [kitStatus]);

  //   const [putKitStatus, { loading: putKitStatusLoading }] =
  //     useMutation(PUT_KIT_STATUS);

  const onSubmit = async (e) => {
    let payload = {};

    console.log(e)

    // const resp = await putKitStatus({ variables: { info: payload } });
    // try {
    //   let { status, body } = resp.data.putKitStatus;
    //   if (status === 200) {
    //     //TODO: Verify Status Code AND DATA (body)
    //     setFormSuccess(true);
    //   }
    // } catch (err) {
    //   console.log('Error with putKitStatus: ', err, resp);
    // }
  };

  console.log(props);

  //   if (putKitStatusLoading) {
  //     return <CircularProgress />;
  //   } else if (formSuccess) {
  //     return (
  //       <div>
  //         <h2>Kit Status updated!</h2>
  //       </div>
  //     );
  //   } else {
  return (
    <Form style={{ display: 'block' }} onSubmit={onSubmit}>
      <Table
        labels={[
          {
            alignment: 'left',
            label: 'Kit ID',
            key: 'id',
            size: 'small',
          },
          {
            alignment: 'left',
            label: 'Status',
            key: 'status',
            size: 'small',
          },

          {
            alignment: 'left',
            label: 'Patient Name',
            key: 'fullName',
            size: 'small',
          },
          {
            alignment: 'left',
            label: 'Patient ID',
            key: 'patientId',
            size: 'small',
          },
        ]}
        data={bulkSelectedKits}
      />
      <Select
        label={'Update Status'}
        value={kitStatus}
        id="status"
        name="status"
        options={kitStatusOptions}
        onChange={(value) => setKitStatus(value)}
        required
      />
      <Button label="Update" id="submit" type="submit"/>
    </Form>
  );
  //   }
}
