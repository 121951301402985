import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { Search } from '@mui/icons-material';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import './Style.css';

export default function TextInput(props) {
  const [value, setValue] = useState(props.value ? props.value : '');

  const handleOnChange = (e) => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  useEffect(() => {
    setValue(props.value ? props.value : '');
  }, [props.value]);

  const registration =
    props.register && props.form
      ? props.register(props.name ? props.name : props.id)
      : null;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && props.onEnterListener) {
      e.preventDefault();
      props.onEnterListener();
    }
  };

  return (
    <div className="SearchBar_Container" style={{ ...props.style }}>
      <div>
        <Search className="SearchBar_Icon" />
      </div>
      <div className="SearchBar_InputHolder">
        <input
          type="text"
          className="SearchBar_Input"
          placeholder={props.placeholder}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          onKeyDown={handleKeyPress}
        />
      </div>
      {props.onEnterListener && (
        <div>
          <KeyboardReturnIcon
            className="SearchBar_Icon"
            style={{
              // color: value && value.length > 0 ? 'white' : '#2f486e',
              // transition: 'color linear 0.2s',
              cursor: 'pointer',
            }}
            onClick={() => {
              props.onEnterListener();
            }}
          />
        </div>
      )}
    </div>
  );
}

TextInput.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

TextInput.defaultProps = {
  id: 'NO-ID-PROVIDED',
  variant: 'filled',
  onClick: undefined,
  type: null,
};
