import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar, Button, TableCard } from 'Stories';
import { Paper } from '@mui/material';
import { pageSection } from 'Services/MUIStylesOverrides';

import './index.css';

const TABLE_HEADERS = [
  {
    name: 'Clinic',
    key: 'name',
  },
  {
    name: 'Ordered By',
    key: 'orderedBy',
  },
  {
    name: 'Kits',
    key: 'numberOfSwabs',
    align: 'right',
  },
];

// STATUS_TRANSLATOR[item.status]

export default function DataTable({
  data,
  loading,
  noHeader,
  selectedData,
  showReviewButton,
  handleCreateNew,
  rowOnClick,
  didClickTab,
  tabSelected,
  ...props
}) {
  const [search, setSearch] = useState('');
  const [displayedData, setDisplayedData] = useState([]);

  useEffect(() => {
    setDisplayedData(data);
  }, [data]);

  return (
    <Paper
      sx={{
        ...pageSection,
        height: 'fit-content',
      }}
      className="clinics-section"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <div
          className={
            'Orders_TabName ' +
            (tabSelected === 'outstanding' ? 'Orders_TabSelected' : '')
          }
          onClick={() => {
            didClickTab('outstanding');
          }}
        >
          Outstanding
        </div>
        <div
          className={
            'Orders_TabName ' +
            (tabSelected === 'fulfilled' ? 'Orders_TabSelected' : '')
          }
          onClick={() => {
            didClickTab('fulfilled');
          }}
        >
          Fulfilled
        </div>
      </div>
      <div style={{ padding: 24 }}>
        <SearchBar
          placeholder={'Search Orders'}
          value={search}
          onChange={(e) => setSearch(e)}
        />
        <div style={{ height: '25px' }}> </div>
        <TableCard
          data={displayedData}
          headers={TABLE_HEADERS}
          loading={loading}
          onRowClick={rowOnClick}
          selectedData={selectedData}
          selectedKey={'id'}
        />
        <div className="BatchTable_NewButtonHolder">
          <Button
            style={{
              borderRadius: 30,
              width: '255px',
            }}
            label="Create New Order"
            onClick={handleCreateNew}
          />
        </div>
      </div>
    </Paper>
  );
}
