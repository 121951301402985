import { gql } from '@apollo/client';

export const LIST_REQUESTS = gql`
  query ($info: PaginationInput) {
    listRequests(info: $info) {
      status
      body
    }
  }
`;

export const GET_REQUEST_DETAILS = gql`
  query ($id: String) {
    getRequestDetails(id: $id) {
      status
      body
    }
  }
`;
