import { gql } from '@apollo/client';

export const CREATE_BATCH = gql`
  mutation {
    createBatch {
      status
      body
    }
  }
`;

export const ADD_KIT_TO_BATCH = gql`
  mutation ($kitId: String, $batchId: String) {
    addKitToBatch(kitId: $kitId, batchId: $batchId) {
      status
      body
    }
  }
`;

export const FINALIZE_BATCH = gql`
  mutation ($batchId: String) {
    finalizeBatch(batchId: $batchId) {
      status
      body
    }
  }
`;

export const PUT_KIT_FOR_ORDER = gql`
  mutation ($info: PutKitForOrderInfo) {
    putKitForOrder(info: $info) {
      status
      body
    }
  }
`;
