import React, { useState, useRef } from 'react';
import { DisplayBarcode } from '../Barcode/DisplayBarcode';
import { Form, Select, Table, Button } from 'Stories';
import { CircularProgress } from '@mui/material';
import { useReactToPrint } from 'react-to-print';

import { useMutation } from '@apollo/client';
import { PUT_KIT_STATUS } from 'Pages/Inventory/GraphQL/mutations';

const kitStatusOptions = [
  { value: 'received', label: 'Received' },
  { value: 'analyzing', label: 'Analyzing' },
  { value: 'completed', label: 'Completed' },
];

export default function ViewKitFormContent(props) {
  const [kitStatus, setKitStatus] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);

  const {
    id: kitId,
    patientId,
    status,
    statusHistory,
    clinicId,
  } = props.kitModalInfo;

  const barcodeRef = useRef();

  const [putKitStatus, { loading: putKitStatusLoading }] =
    useMutation(PUT_KIT_STATUS);

  const onSubmit = async (e) => {
    let payload = {
      id: kitId,
      patientId,
      status: e.status,
      clinicId,
    };

    console.log('KIT STATUS: ', payload);

    const resp = await putKitStatus({ variables: { info: payload } });
    try {
      let { status, body } = resp.data.putKitStatus;
      if (status === 200) {
        //TODO: Verify Status Code AND DATA (body)
        setFormSuccess(true);
      }
    } catch (err) {
      console.log('Error with putKitStatus: ', err, resp);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => barcodeRef.current,
    pageStyle: `@page { size: 2in 2.5in; margin: 0; }`, // Size of page template
  });

  if (putKitStatusLoading) {
    return <CircularProgress />;
  } else if (formSuccess) {
    return (
      <div>
        <h2>Kit Status updated!</h2>
      </div>
    );
  } else {
    return (
      <Form style={{ display: 'block' }} onSubmit={onSubmit}>
        <h2>ID: {kitId}</h2>
        <h2>
          Patient ID:{' '}
          {/* Temporary UI Code to display something for null patient ID */}
          {patientId ? patientId : 'Unregistered'}
        </h2>
        <h2>Status: {status ? status : 'No Status Available'}</h2>
        {statusHistory && (
          <div
            style={{
              border: '1px solid lightgrey',
              margin: '3rem 0',
              borderRadius: '5px',
            }}
          >
            <Table
              labels={[
                {
                  alignment: 'left',
                  label: 'Date',
                  key: 'date',
                },
                {
                  alignment: 'left',
                  label: 'Status',
                  key: 'status',
                },
              ]}
              data={statusHistory}
            />
          </div>
        )}
        <Select
          label={'Update Status'}
          value={kitStatus}
          id="status"
          name="status"
          options={kitStatusOptions}
          onChange={(value) => setKitStatus(value)}
          required
        />
        <Button label="Update" id="submit" type="submit" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DisplayBarcode kitId={kitId} ref={barcodeRef} />
          <Button
            label="Print Barcode"
            id="printBarcode"
            onClick={handlePrint}
          />
        </div>
      </Form>
    );
  }
}
