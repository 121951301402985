import { gql } from '@apollo/client';

export const LIST_GENE_INTERACTIONS = gql`
  query ($info: PaginationInput) {
    listGeneInteractions(info: $info) {
      status
      body
    }
  }
`;

export const COUNT_GENE_INTERACTIONS = gql`
  query {
    countGeneInteractions {
      status
      body
    }
  }
`;
