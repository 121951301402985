import React, { useState, useContext, useEffect } from 'react';
import { Page, Button, TextInput, Modal } from 'Stories';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { ScanKitsModal, CheckKit } from 'Components';
import CreateOrderModal from './CreateOrderModal.js';

import { store } from 'Contexts/GlobalState';
import {
  LARGE_WINDOW_SIZE,
  MEDIUM_WINDOW_SIZE,
  SMALL_WINDOW_SIZE,
} from 'Services/GlobalConstants';
import { useWindowDimensions } from 'Services/CustomHooks';

import {
  LIST_ORDERS,
  GET_KITS_FOR_ORDER,
  GET_KITS_IN_INVENTORY,
  GET_KITS_CHECKED_OUT,
  GET_ORDER,
} from './GraphQL/queries.js';
import {
  PUT_ORDER_STATUS,
  ADD_KITS_TO_INVENTORY,
  UPDATE_ORDER,
} from './GraphQL/mutations.js';

import { useLazyQuery, useMutation } from '@apollo/client';

import { OrdersInfo, OrdersTable, OrderHighlightNumber } from 'Components';

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  marginTop: '20px',
};

const STATUS_TRANSLATOR = {
  created: 'Created',
  ready_for_delivery: 'Ready for Delivery',
  delivered: 'Delivered',
};

export default function Orders(props) {
  const params = useParams();
  const { orderId } = params;

  const [windowWidth, windowHeight] = useWindowDimensions();
  const [orders, setOrders] = useState([]);
  const [kitInventoryModalOpen, setKitInventoryModalOpen] = useState(false);
  const [scanKitModalOpen, setScanKitModalOpen] = useState(false);
  const [createOrderModalOpen, setCreateOrderModalOpen] = useState(false);
  const [kitsCheckedOut, setKitsCheckedOut] = useState(0);

  const [totalKits, setTotalKits] = useState(0);
  const [totalRequested, setTotalRequested] = useState(0);
  const [page, setPage] = useState(0);

  const [numberOfBags, setNumberOfBags] = useState(0);
  const [lotNumber, setLotNumber] = useState(0);

  //OrderInfo
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [numberOfSwabs, setNumberOfSwabs] = useState(0);
  const [kitsToScan, setKitsToScan] = useState(0);
  const [selectedOrderKits, setSelectedOrderKits] = useState([]);

  const [tabSelected, setTabSelected] = useState('outstanding');

  // const toggleModal = (e) => {
  //   setModalInfo(e);
  //   setModalOpen(!modalOpen);
  // };

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;
  let navigate = useNavigate();

  const [
    listOrders,
    { loading: isOrdersLoading, data: taskData, error: taskError },
  ] = useLazyQuery(LIST_ORDERS, {
    fetchPolicy: 'no-cache',
  });

  const [getKitsForOrder, { loading: isKitsForOrdersLoading }] = useLazyQuery(
    GET_KITS_FOR_ORDER,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getOrder, { loading: getOrderLoading }] = useLazyQuery(GET_ORDER, {
    fetchPolicy: 'no-cache',
  });

  const [getKitsInInventory, { loading: isKitsInInventoryLoading }] =
    useLazyQuery(GET_KITS_IN_INVENTORY, {
      fetchPolicy: 'no-cache',
    });

  const [getKitsCheckedOut, { loading: isKitsCheckedOutLoading }] =
    useLazyQuery(GET_KITS_CHECKED_OUT, {
      fetchPolicy: 'no-cache',
    });

  const [putOrderStatus, { loading: updateOrderStatusLoading }] = useMutation(
    PUT_ORDER_STATUS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [updateOrder, { loading: updateOrderLoading }] = useMutation(
    UPDATE_ORDER,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const loadSingleOrder = async () => {
    let resp = await getOrder({
      variables: {
        orderId: orderId,
      },
    });

    let o = JSON.parse(resp.data.getOrder.body);
    setSelectedOrder(o);
  };

  useEffect(() => {
    if (orderId) {
      loadSingleOrder();
    }
  }, []);

  const [addKitsToInventory, { loading: addKitsToInventoryLoading }] =
    useMutation(ADD_KITS_TO_INVENTORY, {
      fetchPolicy: 'no-cache',
    });

  const getOrders = async (e) => {
    let resp = await listOrders({
      variables: {
        info: {
          page: page,
          limit: 10,
        },
        type: e,
      },
    });

    let data = JSON.parse(resp.data.listOrders.body);
    for (var i = 0; i < data.length; ++i) {
      data[i].active = data[i].active === false ? 'No' : 'Yes';
      data[i].displayStatus = STATUS_TRANSLATOR[data[i].status];
      data[i].actions = {
        edit: true,
        delete: false,
      };
    }

    setOrders(data);

    if (e === 'outstanding') {
      let sum = 0;

      for (var i = 0; i < data.length; ++i) {
        sum += parseInt(data[i].numberOfSwabs);
      }

      setTotalRequested(sum);
    }
  };

  const getOrderKits = async (orderId) => {
    let resp = await getKitsForOrder({
      variables: {
        orderId,
      },
    });
    let data = JSON.parse(resp.data.getKitsForOrder.body);
    setSelectedOrderKits(data ? data : []);
    return data ? data : [];
  };

  const updateOrderDateCompleted = async (orderId, newStatus) => {
    console.log(new Date().toISOString().replace('T', ' ').substring(0, 19));
    let dateCompleted = null;
    if (newStatus === 'delivered')
      dateCompleted = new Date()
        .toISOString()
        .replace('T', ' ')
        .substring(0, 19);

    let resp = await updateOrder({
      variables: {
        info: {
          orderId,
          dateCompleted,
        },
      },
    });
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    const needsDateCompletedChange =
      (newStatus === 'delivered' && selectedOrder.status !== 'delivered') ||
      (selectedOrder.status === 'delivered' && newStatus !== 'delivered');

    let resp = await putOrderStatus({
      variables: {
        orderId,
        status: newStatus,
      },
    });

    let data = JSON.parse(resp.data.putOrderStatus.body);

    try {
      //* Update UI with New Status
      if (data.id) {
        const updatedOrder = {
          ...selectedOrder,
          status: data.status,
          displayStatus: STATUS_TRANSLATOR[data.status],
        };

        let updatedOrderList = orders;

        for (let [ind, order] of updatedOrderList.entries()) {
          //* Removing Order from List if Status updated to Delivered or Vice Verse (Outstanding vs Fulfilled)
          if (needsDateCompletedChange) {
            await updateOrderDateCompleted(orderId, newStatus);
            updatedOrderList.splice(ind, 1);
            break;
          }

          //* Update Orders List with New Status
          if (order.id === selectedOrder.id) {
            updatedOrderList[ind] = updatedOrder;
          }
        }
        setOrders(updatedOrderList);
        setSelectedOrder(updatedOrder);
      }
    } catch (e) {
      //TODO
    }
  };

  const rowOnClick = (order) => {
    setSelectedOrder(order);
  };

  const handleTabClick = (e) => {
    getOrders(e);
    setTabSelected(e);
  };

  useEffect(() => {
    getOrders('outstanding');
    initKitsInInventory();
    initKitsCheckedOut();
  }, []);

  useEffect(() => {
    if (selectedOrder) {
      getOrderKits(selectedOrder.id);
    }
  }, [selectedOrder]);

  const toggleKitInventoryModal = () => {
    setKitInventoryModalOpen(!kitInventoryModalOpen);
  };

  const toggleScanKitModal = (e) => {
    setKitsToScan(e);
    setScanKitModalOpen(!scanKitModalOpen);
  };

  const toggleCreateOrderModal = (e) => {
    setCreateOrderModalOpen(!createOrderModalOpen);
    if (e === 'created') {
      getOrders(tabSelected);
    }
  };

  const onSubmitForm = async (e) => {
    let newKitAmt = totalKits + numberOfBags * numberOfSwabs;
    setTotalKits(newKitAmt);

    let resp = await addKitsToInventory({
      variables: {
        number: numberOfBags * numberOfSwabs,
        lotNumber: lotNumber,
      },
    });

    console.log('RESP ', resp);

    toggleKitInventoryModal();
  };

  const getShouldFlex = () => {
    // console.log(document.getElementById('OrderHighlightContainer').offsetWidth);
    let isLargerThanWidth =
      document.getElementById('OrderHighlightContainer') &&
      document.getElementById('OrderHighlightContainer').offsetWidth <=
        SMALL_WINDOW_SIZE;

    return isLargerThanWidth ? '' : 'flex';
  };

  const initKitsInInventory = async () => {
    let resp = await getKitsInInventory();
    let c = JSON.parse(resp.data.getKitsInInventory.body).count;
    setTotalKits(c);
  };

  const initKitsCheckedOut = async () => {
    let resp = await getKitsCheckedOut();
    let c = JSON.parse(resp.data.getKitsCheckedOut.body).count;
    setKitsCheckedOut(c);
  };

  return (
    <>
      <Page pageTitle="Orders">
        <div id="OrderHighlightContainer" style={{ display: getShouldFlex() }}>
          <OrderHighlightNumber
            number={totalKits}
            text={'Available Kits'}
            isError={totalKits < totalRequested}
            errorMessage={'Not enough kits available to fulfill orders'}
            onClick={toggleKitInventoryModal}
            loading={isKitsInInventoryLoading}
          />
          <OrderHighlightNumber
            number={totalRequested}
            text={'Kits Requested'}
            loading={isOrdersLoading}
          />
          <OrderHighlightNumber
            number={kitsCheckedOut}
            text={'Kits Checked Out'}
            loading={isKitsCheckedOutLoading}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns:
              windowWidth < MEDIUM_WINDOW_SIZE ? '1fr' : '2fr 3fr',
          }}
        >
          <OrdersTable
            data={orders}
            loading={isOrdersLoading}
            selectedData={selectedOrder}
            rowOnClick={rowOnClick}
            didClickTab={handleTabClick}
            tabSelected={tabSelected}
            handleCreateNew={toggleCreateOrderModal}
            noHeader
          />
          {windowWidth < MEDIUM_WINDOW_SIZE && (
            <div style={{ height: '25px' }}> </div>
          )}
          <OrdersInfo
            isKitsForOrdersLoading={isKitsForOrdersLoading}
            orderKits={selectedOrderKits}
            order={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            toggleScanKitModal={toggleScanKitModal}
            updateOrderStatus={updateOrderStatus}
            updateOrderStatusLoading={updateOrderStatusLoading}
          />
        </div>
      </Page>
      {/* Scan Kits Modal */}
      <ScanKitsModal
        ordersPage
        selectedOrder={selectedOrder}
        prescannedKits={selectedOrderKits}
        setSelectedKits={setSelectedOrderKits}
        open={scanKitModalOpen}
        kitsToScan={kitsToScan}
        toggle={() => setScanKitModalOpen(!scanKitModalOpen)}
      />
      {/* Kit Inventory Modal */}
      <Modal
        open={kitInventoryModalOpen}
        toggle={() => toggleKitInventoryModal()}
        title={'Kit Inventory'}
      >
        <TextInput
          label="Number of Bags"
          name="numberOfBags"
          id="numberOfBags"
          onChange={(e) => setNumberOfBags(e)}
          form
          required
        />
        <TextInput
          label="Number of Swabs in Bags"
          name="numberOfSwabs"
          id="numberOfSwabs"
          onChange={(e) => setNumberOfSwabs(e)}
          required
        />
        <TextInput
          label="Lot Number"
          name="lotNumber"
          id="lotNumber"
          onChange={(e) => setLotNumber(e)}
          required
        />
        <Button style={buttonStyle} label="submit" onClick={onSubmitForm}>
          Submit
        </Button>
      </Modal>
      {/* Create Order Modal */}
      <CreateOrderModal
        open={createOrderModalOpen}
        toggle={toggleCreateOrderModal}
      />
      <CheckKit />
    </>
  );
}
