import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  FINALIZE_BATCH,
  ADD_KIT_TO_BATCH,
  PUT_KIT_FOR_ORDER,
} from '../GraphQL/mutations';

import './index.css';

const IS_TESTING = window.location.href.indexOf('localhost') > -1;

export default function ScanKitsModal({
  open,
  toggle,
  kitsToScan,
  prescannedKits,
  ordersPage,
  selectedOrder,
  setSelectedKits,
  batchesPage,
  batchId,
}) {
  const inputRef = useRef();

  const [scanModalKits, setScanModalKits] = useState([]);
  const [confirmedKits, setConfirmedKits] = useState([]);
  const [failedKits, setFailedKits] = useState([]);

  const [readyToScan, setReadyToScan] = useState(false);

  const [finalizeBatch, { loading: finalizeBatchLoading }] = useMutation(
    FINALIZE_BATCH,
    {}
  );

  const [addKitToBatch, { loading: addKitLoading }] = useMutation(
    ADD_KIT_TO_BATCH,
    {}
  );
  const [putKitForOrder, { loading: putKitForOrderLoading }] = useMutation(
    PUT_KIT_FOR_ORDER,
    {}
  );

  useEffect(() => {
    showPreScannedKits();
  }, [prescannedKits]);

  useEffect(() => {
    if (!open) {
      setScanModalKits([]);
      setConfirmedKits([]);
      setFailedKits([]);
      setReadyToScan(false);
    } else {
      showPreScannedKits();
      setTimeout(() => {
        focusOnField();
      }, 2000);
    }
  }, [open]);

  const showPreScannedKits = () => {
    // console.log('prescannedKits', prescannedKits);
    let cleanArr = [];
    if (prescannedKits && prescannedKits.length) {
      for (var i = 0; i < prescannedKits.length; ++i) {
        cleanArr.push(
          !ordersPage ? prescannedKits[i].kitId : prescannedKits[i].id
        );
      }
    }
    setScanModalKits(cleanArr);
    setConfirmedKits(cleanArr);
  };

  const addKit = async (id) => {
    if (ordersPage) {
      let resp = await putKitForOrder({
        variables: {
          info: {
            kitId: id,
            orderId: selectedOrder.id,
            clinic: selectedOrder.clinicId,
          },
        },
      });
      // console.log('New Kit Added: ', resp);
      const newKit = JSON.parse(resp.data.putKitForOrder.body);
      try {
        setSelectedKits([...prescannedKits, newKit]);
        setTimeout(() => {
          setConfirmedKits([...confirmedKits, id]);
        }, 750);
      } catch (e) {
        setFailedKits([...failedKits, id]);
      }
    } else if (batchesPage) {
      let resp = await addKitToBatch({
        variables: {
          kitId: id,
          batchId,
        },
      });
      // console.log('New Kit Added: ', resp);
      let newKit = JSON.parse(resp.data.addKitToBatch.body);
      try {
        setSelectedKits([...prescannedKits, newKit]);
        setTimeout(() => {
          setConfirmedKits([...confirmedKits, id]);
        }, 750);
      } catch (e) {
        setFailedKits([...failedKits, id]);
      }
    }
  };

  function randomString(length) {
    var result = '';
    let chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      let id = inputRef.current.value;

      if (id) {
        if (IS_TESTING) {
          id = id + '_' + randomString(5);
        }

        addKit(id);
        setScanModalKits([...scanModalKits, id]);
        inputRef.current.value = '';
      }
    }
  };

  const onFocus = () => setReadyToScan(true);
  const onBlur = () => setReadyToScan(false);

  // const triggerFinalizeBatch = async () => {
  //   console.log('BATCH: ', modalInfo.id);

  //   let resp = await finalizeBatch({
  //     variables: {
  //       batchId: modalInfo.id,
  //     },
  //   });

  //   console.log(resp);
  // };

  const focusOnField = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const getCurrentStatus = (item) => {
    if (failedKits.indexOf(item) > -1) {
      return <FontAwesomeIcon size="xl" icon={solid('xmark')} color="#fff" />;
    } else if (confirmedKits.indexOf(item) > -1) {
      return <FontAwesomeIcon size="xl" icon={solid('check')} color="#fff" />;
    } else {
      return (
        <CircularProgress
          height={15}
          width={15}
          style={{ height: '15px', width: '15px' }}
        />
      );
    }
  };

  const getBackgroundColor = () => {
    const color = '#50C878';
    if (failedKits.length > 0) {
      color = '#FA5F55';
    } else if (scanModalKits.length === 99) {
      color = '#7DF9FF';
    }
    return color;
  };

  return (
    <Modal open={open} onClose={toggle}>
      {/* Add close modal icon */}
      <Box
        sx={style}
        style={{
          backgroundColor: getBackgroundColor(),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowX: 'auto',
        }}
      >
        {/* Header */}
        <div
          style={{
            // border: '1px solid red',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {kitsToScan !== false && (
            <h2 className="header">
              {failedKits.length > 0
                ? 'Kit Invalid'
                : scanModalKits.length === kitsToScan
                ? 'Completed'
                : `${confirmedKits.length}/${kitsToScan} Kits Scanned`}
            </h2>
          )}
        </div>
        {/* Start Scanning/Kit IDs */}
        {scanModalKits.length === 0 ? (
          <div className="start-header">
            <h3 className="header">Start Scanning</h3>
          </div>
        ) : (
          <div className="scanned-kits-container">
            {scanModalKits.map((kitId, index) => {
              return (
                <div className="scanned-kit" key={index}>
                  <p className="scanned-kit-text">{`${index + 1}. ${kitId}`}</p>
                  {getCurrentStatus(kitId)}
                </div>
              );
            })}
          </div>
        )}
        <div style={{ opacity: '0' }}>
          <input
            aria-autocomplete="none"
            autocomplete="off"
            // autocomplete="false"
            type="text"
            name="kit"
            id="kit"
            ref={inputRef}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
          />
        </div>
      </Box>
    </Modal>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '75%',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const testKits = [
  // '19273920',
  // '19273921',
  // '19273922',
  // '19273923',
  // '19273924',
  // '19273925',
  // '19273926',
  // '19273927',
  // '19273928',
  // '19273929',
  //   10
  // '19273920',
  // '19273921',
  // '19273922',
  // '19273923',
  // '19273924',
  // '19273925',
  // '19273926',
  // '19273927',
  // '19273928',
  // '19273929',
  //   30
  // '19273920',
  // '19273921',
  // '19273922',
  // '19273923',
  // '19273924',
  // '19273925',
  // '19273926',
  // '19273927',
  // '19273928',
  // '19273929',
  // 40
  //   '19273920',
  //   '19273921',
  //   '19273922',
  //   '19273923',
  //   '19273924',
  //   '19273925',
  //   '19273926',
  //   '19273927',
  //   '19273928',
  //   '19273929',
  //   50
  //   '19273920',
  //   '19273921',
  //   '19273922',
  //   '19273923',
  //   '19273924',
  //   '19273925',
  //   '19273926',
  //   '19273927',
  //   '19273928',
  //   '19273929',
];
