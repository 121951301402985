import { useState, useEffect } from 'react';

import { List } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import logo from 'Assets/Logo/Color_150ppi.png';

import ExpandButton from './ExpandButton';
import MenuHeader from './MenuHeader';
import FooterButtons from './Footer';
import { accountSettingsMenu, AllMenuItems } from './MenuItems.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  LARGE_WINDOW_SIZE,
  MOBILE_WINDOW_SIZE,
} from 'Services/GlobalConstants';

import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'Services/CustomHooks';

import Drawer from '@mui/material/Drawer';

import { useCookies } from 'react-cookie';

import { BasicListItem } from 'Stories';
import Routes from './Routes';

import './Menu.css';

export default function MiniDrawer(props) {
  let navigate = useNavigate();

  const [cookies] = useCookies(['cookie-name']);

  const [open, setOpen] = useState(true);

  const [expandButtonPos, setExpandButtonPos] = useState(5);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [pageName, setPageName] = useState(window.location.pathname);

  const [windowWidth] = useWindowDimensions();

  useEffect(() => {
    setExpandButtonPos(open ? 305 : 5);
  }, [open]);

  useEffect(() => {
    setOpen(windowWidth > LARGE_WINDOW_SIZE);
  }, [windowWidth]);

  const navigateToPage = (page, extras, name) => {
    setOpen(false);
    setPageName(page);
    navigate(page, extras);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer
        variant={windowWidth >= LARGE_WINDOW_SIZE ? 'permanent' : 'temporary'}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{ sx: { width: `300px !important` } }}
        sx={{ width: 300 }}
      >
        <MenuHeader
          icon={<FontAwesomeIcon icon={solid('warehouse')} />}
          title={'Inventory'}
        />

        {AllMenuItems.map((group, index) => {
          let sectionItems = group.items;
          return (
            <div style={{ padding: 'none' }}>
              {group.sectionTitle && (
                <h4 className={'menu-section-header'}>{group.sectionTitle}</h4>
              )}
              <List className="Menu_ListStyle">
                {sectionItems.map((item) => {
                  return (
                    <BasicListItem
                      key={item.name + '_DrawerListing'}
                      className="Menu_ListItemStyle"
                      selected={pageName === item.path}
                      item={item}
                      open={open}
                      color={'white'}
                      handleClick={() => {
                        navigateToPage(item.path, item.extras, item.name);
                      }}
                    />
                  );
                })}
              </List>
            </div>
          );
        })}

        <h4
          className={'menu-section-header'}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setIsAccountMenuOpen(!isAccountMenuOpen)}
        >
          Account Settings
          <div className={'menu-section-expand-button'}>
            <FontAwesomeIcon
              icon={solid('angle-down')}
              style={{
                transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                transform: isAccountMenuOpen
                  ? 'rotate(-90deg)'
                  : 'rotate(0deg)',
              }}
            />
          </div>
        </h4>

        <List
          className="Menu_ListStyle"
          style={{
            height: '100%',
          }}
        >
          {accountSettingsMenu.map((item) => {
            return isAccountMenuOpen ? (
              <BasicListItem
                key={item.name + '_DrawerListing'}
                selected={pageName === item.name}
                item={item}
                open={open}
                color={'white'}
                handleClick={() => {
                  navigateToPage(item.path, item.extras, item.name);
                }}
              />
            ) : (
              ''
            );
          })}
          <FooterButtons />
        </List>
        <div className={'logo'}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: '200px',
              marginLeft: '50px',
            }}
          />
        </div>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: windowWidth <= MOBILE_WINDOW_SIZE ? 0 : 3 }}
        style={{
          backgroundColor: '#F1F1F1',
        }}
      >
        <Routes />
      </Box>
      <ExpandButton
        open={open}
        setOpen={setOpen}
        expandButtonPos={expandButtonPos}
      />
    </Box>
  );
}
