import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import { Button } from 'Stories';

import { pageSection } from 'Services/MUIStylesOverrides';
import CircularProgress from '@mui/material/CircularProgress';

import './Style.css';

const ViewFields = [
  {
    label: 'r',
    key: 'contactName',
  },
  {
    label: 'Account Phone',
    key: 'contactNumber',
  },
  {
    label: 'Account Rep',
    key: 'accountOwnerFirstName',
  },
];

export default function Main({ name, id, accountInfo }) {
  const [open, setOpen] = useState(false);

  const toggleModal = () => [setOpen(!open)];

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  return (
    <Paper
      sx={{
        ...pageSection,
        height: 'fit-content',
        cursor: 'pointer',
      }}
      className="PatientCard_Container"
    >
      <div className="PatientCard_Name">{name}</div>
      <div className="PatientCard_InfoHolder">
        <ItemValueRow label="Account Holder" value={accountInfo?.contactName} />
        <ItemValueRow
          label="Account Phone"
          value={formatPhoneNumber(accountInfo?.contactNumber)}
        />
        <ItemValueRow
          label="Account Rep"
          value={
            accountInfo?.accountOwnerFirstName +
            ' ' +
            accountInfo?.accountOwnerLastName
          }
        />
      </div>
      {/* <div className="PatientCard_ButtonHolder">
        <Button
          style={{
            borderRadius: 30,
            minWidth: '255px',
            width: '80%',
            backgroundColor: 'var(--primaryDark)',
          }}
          label={'Send Patient Access'}
          onClick={toggleModal}
        />
      </div> */}
    </Paper>
  );
}

function ItemValueRow({ label, value }) {
  return (
    <div className="PatientCard_InfoItem">
      <div>{label}:</div>
      <div>{value}</div>
    </div>
  );
}
